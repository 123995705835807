<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      name="InstitutionHospitalCreateModal"
      classes="modal-container"
      escToClose
      content-class="modal-content"
    >
      <div class="bg-[#ffffff] w-full">
        <div
          class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">기관 연결</p>
          <button @click="confirm">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
          >
            <div class="flex flex-col w-full space-y-[46px]">
              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  기관
                </p>
                <div
                  @click="showInstitutionModal = true"
                  class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer"
                >
                  <p
                    class="text-[14px] font-[400] text-[#B6B9C3]"
                    v-if="institutionValue === ''"
                  >
                    기관명
                  </p>
                  <p class="text-[14px] font-[400] text-[#202127]" v-else>
                    {{ institutionValue }}
                  </p>
                </div>

                <button
                  @click="showInstitutionModal = true"
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                >
                  <p
                    class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    검색
                  </p>
                </button>
              </div>

              <div
                v-if="institutionValue !== ''"
                class="flex space-x-[24px] items-center"
              >
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  병원
                </p>

                <div
                  @click="showHospitalCreateModal = true"
                  class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer"
                >
                  <p
                    class="text-[14px] font-[400] text-[#B6B9C3]"
                    v-if="busValue === ''"
                  >
                    병원명
                  </p>
                  <p class="text-[14px] font-[400] text-[#202127]" v-else>
                    {{ busValue }}
                  </p>
                </div>

                <button
                  @click="showHospitalCreateModal = true"
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                >
                  <p
                    class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    검색
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="confirm"
              >
                취소
              </button>

              <button
                v-if="busValue !== '' && institutionValue !== '' && !isLoading"
                @click="createLink"
                class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              >
                등록
              </button>

              <button
                v-else
                class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>

    <!-- 기관선택 모달 -->
    <vue-final-modal
      v-model="showInstitutionModal"
      classes="modal-container"
      content-class="modal-content"
      escToClose
    >
      <div class="bg-[#ffffff] w-[70vw] h-full overflow-auto scrollbar-hide">
        <div
          class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">기관 검색</p>
          <button @click="onClose('showInstitutionModal')">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flex-col w-full bg-white rounded-[12px] px-[32px] py-[53px]"
          >
            <div class="flex justify-between mb-[24px]">
              <div class="flex items-center justify-center">
                <input
                  id="save_hospital"
                  type="checkbox"
                  v-model="institutionPossibility"
                  @change="
                    geInstitutionList(
                      1,
                      this.institutionPossibility,
                      this.searchValue
                    );
                    this.page = 1;
                  "
                />
                <label
                  htmlFor="save_hospital"
                  class="flex items-center justify-center exst-[14px] text-[#373943] font-[500] select-none"
                >
                  연결 가능한 기관만 보기
                </label>
              </div>
              <div class="flex">
                <input
                  className="h-[46px] w-[15.6vw] border-[1px] rounded-[8px] px-[20px]  placeholder:text-[14px]"
                  id="searchValue"
                  type="text"
                  v-model="searchValue"
                  autocomplete="off"
                  placeholder="기관명"
                  @keyup.enter="
                    geInstitutionList(
                      1,
                      this.institutionPossibility,
                      this.searchValue
                    );
                    this.page = 1;
                  "
                />

                <button
                  @click="
                    geInstitutionList(
                      1,
                      this.institutionPossibility,
                      this.searchValue
                    );
                    this.page = 1;
                  "
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                >
                  <p
                    class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    검색
                  </p>
                </button>

                <button
                  @click="resetInstitutionFilter"
                  class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-[#DDDFE3]"
                >
                  <p
                    class="text-[#65697B] text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    초기화
                  </p>
                </button>
              </div>
            </div>
            <LoadingSpinner v-if="isLoading"></LoadingSpinner>
            <div v-else class="min-h-[70vh]">
              <table class="bg-[#FAFAFB] rounded-[12px] w-full">
                <thead class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in headerList"
                      :key="index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ headerList[index] }}</p>
                        <div
                          class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody v-if="InstitutionList?.pages?.length !== 0">
                  <tr
                    v-for="(item, index) in InstitutionList.pages"
                    :key="index"
                  >
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{
                          item.status === 'ACTIVE'
                            ? '정상'
                            : item.status === 'DISABLED'
                            ? '종료'
                            : ''
                        }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.institutionName ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] max-w-[250px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.streetAddress ?? '-' }}
                        {{ item.detailAddress ?? '' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.createdDate ?? '-' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.busHospitalName ?? '-' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <div class="w-full flex justify-center items-center">
                        <p
                          v-if="item.possibility === true"
                          @click="
                            saveInstitutionValue(
                              item.institutionName,
                              item.institutionId
                            )
                          "
                          class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-full hover:bg-[#DDDFE3] whitespace-nowrap"
                        >
                          선택
                        </p>
                        <p v-else>-</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div
                  v-else
                  class="w-full h-[50vh] flex items-center justify-center select-none"
                >
                  <div
                    class="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>일치하는 검색 결과가 없습니다.</p>
                  </div>
                </div>
              </table>
            </div>

            <div class="w-full mt-[36px] flex justify-center items-center">
              <pagination
                v-model="page"
                :records="
                  InstitutionList?.totalElementCount
                    ? Number(InstitutionList?.totalElementCount)
                    : 1
                "
                :per-page="15"
                @paginate="paginate"
              />
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>

    <!-- 병원선택 모달 -->
    <vue-final-modal
      v-model="showHospitalCreateModal"
      classes="modal-container"
      content-class="modal-content"
      escToClose
    >
      <div class="bg-[#ffffff] w-[70vw] h-full overflow-auto scrollbar-hide">
        <div
          class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">병원 검색</p>
          <button @click="onClose('showHospitalCreateModal')">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>

        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flex-col w-full bg-white rounded-[12px] px-[32px] py-[53px]"
          >
            <div class="flex justify-between mb-[24px]">
              <div class="flex items-center justify-center">
                <input
                  id="save_bus"
                  type="checkbox"
                  v-model="hospitalPossibility"
                  @change="
                    getHospitalList(
                      1,
                      this.hospitalPossibility,
                      this.searchValue
                    );
                    this.hospitalpage = 1;
                  "
                />
                <label
                  htmlFor="save_bus"
                  class="flex items-center justify-center exst-[14px] ml-[10px] text-[#373943] font-[500] select-none"
                >
                  연결 가능한 병원만 보기
                </label>
              </div>
              <div class="flex">
                <input
                  className="h-[46px] w-[15.6vw] border-[1px] rounded-[8px] px-[20px]  placeholder:text-[14px]"
                  id="searchValue"
                  type="text"
                  v-model="searchValue"
                  autocomplete="off"
                  placeholder="병원명"
                  @keyup.enter="
                    getHospitalList(
                      1,
                      this.hospitalPossibility,
                      this.searchValue
                    );
                    this.hospitalpage = 1;
                  "
                />

                <button
                  @click="
                    getHospitalList(
                      1,
                      this.hospitalPossibility,
                      this.searchValue
                    );
                    this.hospitalpage = 1;
                  "
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                >
                  <p
                    class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    검색
                  </p>
                </button>

                <button
                  @click="resetBusFilter"
                  class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-[#DDDFE3]"
                >
                  <p
                    class="text-[#65697B] text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    초기화
                  </p>
                </button>
              </div>
            </div>
            <LoadingSpinner v-if="isLoading"></LoadingSpinner>
            <div v-else class="min-h-[70vh]">
              <table class="bg-[#FAFAFB] rounded-[12px] w-full">
                <thead class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in hostpioiHeaderList"
                      :key="index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">
                          {{ hostpioiHeaderList[index] }}
                        </p>
                        <div
                          class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="hospitalList?.pages?.length !== 0">
                  <tr v-for="(item, index) in hospitalList.pages" :key="index">
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{
                          item.status === 'ACTIVE'
                            ? '정상'
                            : item.status === 'DISABLED'
                            ? '종료'
                            : ''
                        }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.name ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] max-w-[200px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.streetAddress ?? '-' }}
                        {{ item.detailAddress ?? '' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.createdDate ?? '-' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.vehicleNumber ?? '-' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <div class="w-full flex justify-center items-center">
                        <p
                          @click="
                            saveHostpitalValue(item.busHospitalId, item.name)
                          "
                          v-if="item.possibility === true"
                          class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-full hover:bg-[#DDDFE3] whitespace-nowrap"
                        >
                          선택
                        </p>
                        <p v-else>-</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div
                  v-else
                  class="w-full h-[50vh] flex items-center justify-center select-none"
                >
                  <div
                    class="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>일치하는 검색 결과가 없습니다.</p>
                  </div>
                </div>
              </table>
            </div>
          </div>
          <div class="w-full mt-[36px] flex justify-center items-center">
            <pagination
              v-model="hospitalpage"
              :records="
                hospitalList?.totalElementCount
                  ? Number(hospitalList?.totalElementCount)
                  : 1
              "
              :per-page="15"
              @paginate="hospitalPaginate"
            />
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import InstitutionHospitalService from '@/services/InstitutionHospitalService';
import Pagination from 'v-pagination-3';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { useToast } from 'vue-toastification';

export default {
  name: 'InstitutionHospitalCreateModal',
  components: {
    Pagination,
    LoadingSpinner,
  },

  props: {
    update: Function,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    isLoading: false,
    page: 1,
    hospitalpage: 1,
    totalPages: 1,
    currentElementCount: 1,
    showModal: false,
    modalType: '',

    showInstitutionModal: false,
    institutionPossibility: false,
    institutionValue: '',
    institutionId: '',

    showHospitalCreateModal: false,
    hospitalPossibility: false,
    hospitalId: '',
    busValue: '',

    headerList: ['상태', '기관', '주소', '등록일', '연결병원', '선택'],

    hostpioiHeaderList: ['상태', '병원', '주소', '등록일', '연결버스', '선택'],
    searchValue: '',
    InstitutionList: {},
    hospitalList: {},
  }),

  updated() {
    if (this.showModal === false) {
      this.modalType = '';
      this.institutionId = '';
      this.hospitalId = '';
      this.institutionValue = '';
      this.busValue = '';
    }

    if (this.showInstitutionModal === true) {
      this.geInstitutionList(
        this.page,
        this.institutionPossibility,
        this.searchValue
      );
    }

    if (this.showInstitutionModal === false) {
      this.page = 1;
      this.institutionPossibility = false;
      this.searchValue = '';
    }

    if (this.showHospitalCreateModal === true) {
      this.getHospitalList(
        this.page,
        this.hospitalPossibility,
        this.searchValue
      );
    }

    if (this.showHospitalCreateModal === false) {
      this.hospitalpage = 1;
      this.hospitalPossibility = false;
      this.searchValue = '';
    }
  },

  methods: {
    onClose(modal) {
      this[modal] = false;
    },

    confirm() {
      this.showModal = false;
      this.institutionValue = '';
      this.busValue = '';
    },

    paginate() {
      this.geInstitutionList(
        this.page,
        this.institutionPossibility,
        this.searchValue
      );
    },

    hospitalPaginate() {
      this.getHospitalList(
        this.hospitalpage,
        this.hospitalPossibility,
        this.searchValue
      );
    },

    saveInstitutionValue(institutionValue, institutionId) {
      this.institutionValue = institutionValue;
      this.institutionId = institutionId;
      this.searchValue = '';
      this.showInstitutionModal = false;
    },

    resetInstitutionFilter() {
      this.institutionPossibility = false;
      this.searchValue = '';
      this.page = 1;

      this.geInstitutionList(
        this.page,
        this.institutionPossibility,
        this.searchValue
      );
    },

    saveHostpitalValue(busHospitalId, name) {
      this.hospitalId = busHospitalId;
      this.busValue = name;
      this.searchValue = '';
      this.showHospitalCreateModal = false;
    },

    resetBusFilter() {
      this.hospitalPossibility = false;
      this.searchValue = '';
      this.hospitalpage = 1;

      this.getHospitalList(
        this.hospitalpage,
        this.hospitalPossibility,
        this.searchValue
      );
    },

    async geInstitutionList(page, status, institutionValue) {
      this.isLoading = true;

      await InstitutionHospitalService.geInstitutionList(
        page,
        status,
        institutionValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.InstitutionList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getInstitutionListError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getHospitalList(page, status, institutionValue) {
      this.isLoading = true;

      await InstitutionHospitalService.getHospitalList(
        page,
        status,
        institutionValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.hospitalList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalListError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async createLink() {
      this.isLoading = true;
      await InstitutionHospitalService.createLink(
        this.institutionId,
        this.hospitalId
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('기관 - 병원 연결이 완료되었습니다.');
            this.showModal = false;
            this.$emit('update', 1, '', '');
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style></style>
