<template>
  <vue-final-modal
    name="institutionUpdateModal"
    v-model="showInstitutionUpdateModal"
    classes="modal-container"
    content-class="modal-content"
    @before-open="beforeOpenInstitutionUpdateModal"
    @closed="closedInstitutionUpdateModal"
    escToClose
  >
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
      <div
        class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">기관 정보 수정</p>
        <button @click="onCloseModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div
          class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
        >
          <div class="flex flex-col w-full">
            <div class="flex space-x-[24px] items-center mb-[20px]">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                기관 번호
              </p>
              <input
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                id="id"
                type="text"
                v-model="updateInfo.id"
                autocomplete="off"
                placeholder="기관 번호"
                disabled
              />
            </div>

            <div class="flex space-x-[24px] items-center">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                기관명
              </p>

              <CustomInput
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                id="name"
                type="text"
                required
                v-model="updateInfo.name"
                autocomplete="off"
                placeholder="기관명"
                maxlength="30"
              />
            </div>

            <div class="flex space-x-[24px] mt-[20px] mb-[30px]">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap relative top-[10%]"
              >
                주소
              </p>

              <div class="flex flex-col space-y-[10px] w-full">
                <div class="flex space-x-[10px]">
                  <div
                    @click="execDaumPostcode()"
                    class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                  >
                    <p
                      class="text-[14px] font-[400] text-[#B6B9C3]"
                      v-if="updateInfo.postNumber === ''"
                    >
                      우편번호
                    </p>
                    <p class="text-[14px] font-[400] text-[#202127]" v-else>
                      {{ updateInfo.postNumber ?? '' }}
                    </p>
                  </div>

                  <button
                    @click="execDaumPostcode()"
                    class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                  >
                    <p
                      class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                    >
                      검색
                    </p>
                  </button>
                </div>

                <div
                  @click="execDaumPostcode()"
                  class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                >
                  <p
                    class="text-[14px] font-[400] text-[#B6B9C3]"
                    v-if="updateInfo.streetAddress === ''"
                  >
                    주소
                  </p>
                  <p class="text-[14px] font-[400] text-[#202127]" v-else>
                    {{ updateInfo.streetAddress ?? '' }}
                  </p>
                </div>

                <CustomInput
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  id="detailAddress"
                  type="text"
                  required
                  v-model="updateInfo.detailAddress"
                  autocomplete="off"
                  placeholder="상세주소"
                  maxlength="50"
                />
              </div>
            </div>

            <div class="flex space-x-[24px] items-center">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                연락처
              </p>
              <input
                @input="checkPhoneNumber($event)"
                maxlength="13"
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                id="phone"
                type="text"
                inputMode="decimal"
                required
                v-model="updateInfo.phone"
                autocomplete="off"
                placeholder="연락처"
              />
            </div>

            <div class="flex mt-[30px] items-center">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap mr-[24px]"
              >
                이메일
              </p>
              <div class="w-full relative">
                <input
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  id="email"
                  type="text"
                  required
                  v-model="updateInfo.email"
                  autocomplete="off"
                  placeholder="이메일"
                />
                <p
                  v-show="validEmail"
                  class="text-[13px] text-[#ff4869] mt-[20px] absolute top-[35px]"
                >
                  이메일 주소를 정확히 입력해주세요.
                </p>
              </div>
            </div>

            <div class="flex space-x-[24px] items-center mt-[30px]">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                상태
              </p>
              <select
                v-if="updateInfo.allocatedHospital === null"
                v-model="selected.status"
                class="bg-white border border-[#DDDFE3] select-none w-full text-[13px] items-center px-[16px] py-[11.5px] rounded-[8px]"
              >
                <option
                  v-for="(item, index) in statusSelect"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name ?? '' }}
                </option>
              </select>

              <div
                v-else
                class="bg-[#FAFAFB] border border-[#DDDFE3] select-none w-full items-center text-[14px] text-[#7E8295] px-[16px] py-[11.5px] rounded-[8px]"
              >
                {{
                  updateInfo.status === 'ACTIVE'
                    ? '정상'
                    : updateInfo.status === 'DISABLED'
                    ? '종료'
                    : ''
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="onCloseModal"
            >
              취소
            </button>

            <button
              @click="updateInstitution"
              v-if="
                !isLoading &&
                updateInfo.name !== '' &&
                updateInfo.postNumber !== '' &&
                updateInfo.streetAddress !== '' &&
                updateInfo.email !== '' &&
                updateInfo.phone !== '' &&
                !validEmail
              "
              class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
            >
              수정
            </button>

            <div
              v-else
              class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
            >
              수정
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
// import Pagination from 'v-pagination-3';
// import LoadingSpinner from '@/components/common/LoadingSpinner';
import InstitutionService from '@/services/InstitutionService';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useToast } from 'vue-toastification';
import CustomInput from '@/components/common/CustomInput.vue';

export default {
  name: 'InstitutionUpdateModal',
  components: { CustomInput },
  setup() {
    const toast = useToast();
    return { toast };
  },

  computed: {
    selected: function () {
      return { status: this.updateInfo.status };
    },
  },

  props: { getInstitutionInfo: Function },

  data: () => ({
    institutionId: null,
    isLoading: false,
    showInstitutionDetailModal: false,
    showInstitutionUpdateModal: false,
    page: 1,
    tab: '검진 내역',
    format,
    ko,
    parseISO,
    validEmail: false,
    statusSelect: [
      { name: '정상', value: 'ACTIVE' },
      { name: '종료', value: 'DISABLED' },
    ],
    updateInfo: {
      id: '',
      name: '',
      postNumber: '',
      streetAddress: '',
      detailAddress: '',
      phone: '',
      email: '',
      status: '',
    },
  }),

  watch: {
    'updateInfo.email': function () {
      this.checkEmail();
    },
  },

  methods: {
    onCloseModal() {
      this.showInstitutionUpdateModal = false;
    },

    beforeOpenInstitutionUpdateModal() {
      this.updateInfo = {
        ...this?.$vfm?.get('institutionUpdateModal')?.[0]?.params?._value
          ?.institutionInfo,
      };
    },

    closedInstitutionUpdateModal() {
      this.updateInfo = {};
    },

    async updateInstitution() {
      this.isLoading = true;
      await InstitutionService.updateInstitution(
        this.updateInfo.id,
        this.updateInfo.name,
        this.updateInfo.postNumber,
        this.updateInfo.streetAddress,
        this.updateInfo.detailAddress,
        this.updateInfo.phone,
        this.updateInfo.email,
        this.selected.status
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('기관 정보가 수정되었습니다.');
            // this.showInstitutionUpdateModal = false;
            // this.$emit('update', this.institutionInfo.id);
            this.onCloseModal();
            this.getInstitutionInfo();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 이메일 형식 검사
    checkEmail() {
      const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.updateInfo.email?.length > 0) {
        this.validEmail = !emailPattern.test(this.updateInfo.email);
      } else {
        this.validEmail = false;
      }
    },

    checkPhoneNumber(event) {
      this.updateInfo.phone = event.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },

    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if (this.extraAddress !== '') {
            this.extraAddress = '';
          }
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            this.updateInfo.streetAddress = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            this.updateInfo.streetAddress = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              this.extraAddress += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              this.extraAddress +=
                this.extraAddress !== ''
                  ? `, ${data.buildingName}`
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (this.extraAddress !== '') {
              this.extraAddress = `(${this.extraAddress})`;
            }
          } else {
            this.extraAddress = '';
          }
          // 우편번호를 입력한다.
          this.updateInfo.postNumber = data.zonecode;
        },
      }).open({ popupKey: 'onione' });
    },
  },
};
</script>

<style></style>
