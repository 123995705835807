<template>
  <div>
    <vue-final-modal
      v-model="showHospitalCreateModal"
      @closed="closedHospitalCreateModal"
      name="HospitalCreateModal"
      classes="modal-container"
      escToClose
      content-class="modal-content"
    >
      <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
        <div
          class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">병원 등록</p>
          <button @click="onClose('showHospitalCreateModal')">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
          >
            <div class="flex flex-col w-full">
              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  병원명
                </p>

                <CustomInput
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  id="hospitalName"
                  type="text"
                  required
                  v-model="hospitalName"
                  autocomplete="off"
                  placeholder="병원명"
                  maxlength="30"
                />
              </div>

              <div class="flex space-x-[24px] my-[20px]">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap relative top-[10%]"
                >
                  주소
                </p>

                <div class="flex flex-col space-y-[10px] w-full">
                  <div class="flex space-x-[10px]">
                    <div
                      @click="execDaumPostcode()"
                      class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                    >
                      <p
                        class="text-[14px] font-[400] text-[#B6B9C3]"
                        v-if="postcode === ''"
                      >
                        우편번호
                      </p>
                      <p class="text-[14px] font-[400] text-[#202127]" v-else>
                        {{ postcode }}
                      </p>
                    </div>

                    <button
                      @click="execDaumPostcode()"
                      class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                    >
                      <p
                        class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                      >
                        검색
                      </p>
                    </button>
                  </div>

                  <div
                    @click="execDaumPostcode()"
                    class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                  >
                    <p
                      class="text-[14px] font-[400] text-[#B6B9C3]"
                      v-if="address === ''"
                    >
                      주소
                    </p>
                    <p class="text-[14px] font-[400] text-[#202127]" v-else>
                      {{ address }}
                    </p>
                  </div>

                  <CustomInput
                    className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                    id="addressDetail"
                    type="text"
                    required
                    v-model="addressDetail"
                    autocomplete="off"
                    placeholder="상세주소"
                    maxlength="50"
                  />
                </div>
              </div>

              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  연락처
                </p>
                <input
                  @input="checkPhoneNumber($event)"
                  maxlength="13"
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  id="phoneNumber"
                  type="text"
                  inputMode="decimal"
                  required
                  v-model="phoneNumber"
                  autocomplete="off"
                  placeholder="연락처"
                />
              </div>

              <div class="flex space-x-[24px] items-center mt-[20px]">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  담당자
                  <br />
                  연락처
                </p>

                <input
                  @input="checkManagerPhoneNumber($event)"
                  maxlength=" 13"
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  id="managerNumber"
                  type="text"
                  inputMode="decimal"
                  required
                  v-model="managerNumber"
                  autocomplete="off"
                  placeholder="담당자 연락처"
                />
              </div>
            </div>
          </div>

          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="onClose('showHospitalCreateModal')"
              >
                취소
              </button>

              <button
                @click="createHospital"
                v-if="
                  !isLoading &&
                  hospitalName !== '' &&
                  postcode !== '' &&
                  address !== '' &&
                  phoneNumber !== '' &&
                  managerNumber !== ''
                "
                class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              >
                등록
              </button>

              <button
                v-else
                class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-default"
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import HospitalService from '@/services/HospitalService';
import CustomInput from '@/components/common/CustomInput.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'HospitalCreateModal',
  components: { CustomInput },
  props: {
    getHospitalList: Function,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    isLoading: false,
    showHospitalCreateModal: false,
    hospitalName: '',
    postcode: '',
    address: '',
    addressDetail: '',
    phoneNumber: '',
    managerNumber: '',
  }),

  mounted() {},

  updated() {
    if (this.showHospitalCreateModal === false) {
      this.hospitalName = '';
      this.postcode = '';
      this.address = '';
      this.addressDetail = '';
      this.phoneNumber = '';
      this.managerNumber = '';
    }
  },

  methods: {
    onClose(modal) {
      this[modal] = false;
    },

    checkPhoneNumber(event) {
      this.phoneNumber = event.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },

    checkManagerPhoneNumber(event) {
      this.managerNumber = event.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },

    closedHospitalCreateModal() {
      this.hospitalName = '';
      this.postcode = '';
      this.address = '';
      this.addressDetail = '';
      this.phoneNumber = '';
      this.managerNumber = '';
    },

    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if (this.extraAddress !== '') {
            this.extraAddress = '';
          }
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            this.address = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            this.address = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              this.extraAddress += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              this.extraAddress +=
                this.extraAddress !== ''
                  ? `, ${data.buildingName}`
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (this.extraAddress !== '') {
              this.extraAddress = `(${this.extraAddress})`;
            }
          } else {
            this.extraAddress = '';
          }
          // 우편번호를 입력한다.
          this.postcode = data.zonecode;
        },
      }).open({ popupKey: 'onione' });
    },

    async createHospital() {
      this.isLoading = true;
      await HospitalService.createHospital(
        this.hospitalName,
        this.postcode,
        this.address,
        this.addressDetail,
        this.phoneNumber,
        this.managerNumber
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('병원 정보가 등록되었습니다.');
            this.getHospitalList();
            this.showHospitalCreateModal = false;
            // this.$emit('update', 1, 'n99', '');
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
