import { createApp } from 'vue';
import App from './App.vue';
import { vfmPlugin } from 'vue-final-modal';
import router from './router';
import store from './store';
import '/styles/index.css';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueClipboard from 'vue3-clipboard';

const options = {
  position: 'bottom-center',
  timeout: 1500,
  pauseOnHover: false,
  closeButton: false,
  hideProgressBar: true,
  draggable: false,
  bodyClassName: ['toast-custom-1', 'toast-custom-2'],
  pauseOnFocusLoss: false,
};

const app = createApp(App);
app.use(router);
app.provide('store', store);
app.use(vfmPlugin);
app.use(VCalendar, {});
app.use(Toast, options);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

app.mount('#app');

<style></style>;
