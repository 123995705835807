import { createWebHistory, createRouter } from 'vue-router';
import LoginPage from '@/pages/LoginPage.vue';
import InstitutionPage from '@/pages/InstitutionPage.vue';
import HospitalPage from '@/pages/HospitalPage.vue';
import BusPage from '@/pages/BusPage.vue';
import InstitutionHospitalPage from '@/pages/InstitutionHospitalPage.vue';
import BusHospitalPage from '@/pages/BusHospitalPage.vue';
import CheckupPage from '@/pages/CheckupPage.vue';
import ReservationPage from '@/pages/ReservationPage.vue';
import NotFound from '@/pages/Error404.vue';
import ServerError from '@/pages/Error500.vue';
import PrivacyPolicy from '@/pages/app/PrivacyPolicyPage.vue';
import store from './store';

const routes = [
  {
    path: '/',
    name: 'ListLayout',
    component: () => import('@/layouts/ListLayout.vue'),
    children: [
      {
        path: '',
        name: 'Institution',
        components: {
          default: InstitutionPage,
        },
        // 엑세스토큰 유효하지 않을 때 접근 못하도록
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'hospital',
        name: 'Hospital',
        components: {
          default: HospitalPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'bus',
        name: 'Bus',
        components: {
          default: BusPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'institution-hospital',
        name: 'InstitutionHospital',
        components: {
          default: InstitutionHospitalPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'bus-hospital',
        name: 'BusHospital',
        components: {
          default: BusHospitalPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'checkup',
        name: 'Checkup',
        components: {
          default: CheckupPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
      {
        path: 'reservation',
        name: 'Reservation',
        components: {
          default: ReservationPage,
        },
        beforeEnter: async (to, from, next) => {
          const isLoggedIn = await store.getAuthStatusServer();
          if (!isLoggedIn) next({ path: '/login' });
          else next();
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    // 엑세스토큰 유효하면 Institution 페이지로 리다이렉트
    beforeEnter: async (to, from, next) => {
      const isLoggedIn = await store.getAuthStatusServer();
      if (isLoggedIn) next({ path: '/' });
      else next();
    },
  },

  //404 에러 라우트
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },

  //404 외 에러 라우트
  {
    path: '/error500',
    name: 'ServerError',
    component: ServerError,
  },
  {
    path: '/app/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;