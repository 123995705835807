<template>
  <vue-final-modal
    name="institutionAccountUpdateModal"
    v-model="showInstitutionAccountUpdateModal"
    classes="modal-container"
    content-class="modal-content"
    @before-open="beforeOpenInstitutionAccountUpdateModal"
    @closed="closedInstitutionAccountUpdateModal"
    escToClose
  >
    <div class="bg-[#ffffff] overflow-auto scrollbar-hide">
      <div
        class="h-[56px] w-[500px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">계정 정보 수정</p>
        <button @click="onCloseModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div class="flex flxe-col bg-white rounded-[12px]">
          <div class="flex flex-col w-full p-[40px]">
            <div class="flex items-center mb-[20px]">
              <label
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                아이디
              </label>
              <input
                className="w-[311px]  h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                type="text"
                v-model="accountInfo.memberAccountName"
                autocomplete="off"
                placeholder="아이디"
                disabled
              />
            </div>
            <div class="flex items-center mb-[20px]">
              <label
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                비밀번호
              </label>
              <button
                class="px-[31.5px] py-[14px] text-[#ffffff] bg-onione-N600 rounded-[8px]"
                @click="onChangePassword"
              >
                초기화
              </button>
            </div>
            <div class="flex items-center mb-[20px] relative">
              <label
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                담당자<br />이메일
              </label>

              <input
                className=" w-[311px] h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                type="text"
                v-model="accountInfo.memberEmail"
                autocomplete="off"
                placeholder="담당자 이메일"
              />
              <p
                v-show="validMemberEmail"
                class="text-[13px] text-[#ff4869] mt-[20px] absolute left-[60px] top-[30px]"
              >
                이메일 주소를 정확히 입력해주세요.
              </p>
            </div>
            <div class="flex items-center relative">
              <label
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                담당자<br />연락처
              </label>

              <input
                @input="checkPhoneNumber($event)"
                maxlength="13"
                className="w-[311px] h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                name="memberPhone"
                type="text"
                inputMode="decimal"
                required
                v-model="accountInfo.memberPhone"
                autocomplete="off"
                placeholder="담당자 연락처"
              />
              <p
                v-show="validMemberPhone"
                class="text-[13px] text-[#ff4869] mt-[20px] absolute left-[60px] top-[30px]"
              >
                담당자 연락처를 정확히 입력해주세요.
              </p>
            </div>
          </div>
        </div>

        <div class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="onCloseModal"
            >
              취소
            </button>

            <button
              @click="updateInstitutionAccount"
              v-if="
                !isLoading &&
                accountInfo.memberAccountName !== '' &&
                accountInfo.memberEmail !== '' &&
                accountInfo.memberPhone !== '' &&
                !validMemberEmail
                // !validMemberPhone
                
              "
              class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
            >
              수정
            </button>

            <div
              v-else
              class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
            >
              수정
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>

  <vue-final-modal
    name="institutionPasswordModal"
    v-model="showInstitutionPasswordModal"
    classes="modal-container"
    content-class="modal-content"
    @closed="closedInstitutionPasswordModal"
    escToClose
  >
    <div class="bg-[#ffffff] overflow-auto scrollbar-hide w-[500px]">
      <div
        class="h-[56px] w-[500px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">비밀번호 변경 완료</p>
        <button @click="onInstitutionPasswordCloseModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div class="flex flxe-col bg-white rounded-[12px]">
          <div class="flex flex-col w-full p-[40px]">
            <div
              class="flex items-center pb-[30px] border-b-[1px] justify-between"
            >
              <div class="flex items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[100px] whitespace-nowrap"
                >
                  변경 비밀번호
                </p>
                <p class="text-onione-N700">{{ newPassword ?? '-' }}</p>
              </div>
              <button
                type="button"
                class="px-[16.5px] py-[7px] border-[1px] rounded-[8px] text-onione-N600"
                v-clipboard:copy="newPassword"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                복사
              </button>
            </div>
            <div class="pt-[24px] text-[14px] text-onione-N500">
              <p class="text-onione-N500">
                해당 기관의 비밀번호가 변경되었습니다.
              </p>
              <p class="text-onione-N500">
                변경된 비밀번호를 기관 담당자에게 안내해 주세요.
              </p>
            </div>
          </div>
        </div>

        <div class="modal__action">
          <div class="flex justify-center w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-onione-P400 rounded-[8px] w-1/2 text-[#ffffff] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="onInstitutionPasswordCloseModal"
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import InstitutionService from '@/services/InstitutionService';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useToast } from 'vue-toastification';

export default {
  name: 'InstitutionAccountUpdateModal',
  components: {},
  setup() {
    const toast = useToast();
    return { toast };
  },

  props: { getAccountInfo: Function },

  data: () => ({
    institutionId: null,
    isLoading: false,
    showInstitutionAccountUpdateModal: false,
    showInstitutionPasswordModal: false,
    page: 1,
    format,
    ko,
    parseISO,
    validMemberEmail: false,
    validMemberPhone: false,
    accountInfo: {
      institutionId: '',
      memberAccountName: '',
      memberEmail: '',
      memberId: '',
      memberPhone: '',
    },
    newPassword: '',
  }),

  watch: {
    'accountInfo.memberEmail': function () {
      this.checkEmail();
    },
    'accountInfo.memberPhone': function () {
      this.watchPhone();
    },
  },

  methods: {
    onCopy(e) {
      this.toast.success(`${e.text} 가 복사되었습니다.`);
    },
    onError() {
      this.toast.error(`비밀번호 복사에 실패했습니다.`);
    },

    watchPhone() {
      const phonePattern = /^[\d-]{1,30}$/;
      if (this.accountInfo.memberPhone?.length > 0) {
        this.validMemberPhone = !phonePattern.test(
          this.accountInfo.memberPhone
        );
      } else {
        this.validMemberPhone = false;
      }
    },

    checkPhoneNumber(event) {
      switch (event.target.name) {
        case 'memberPhone':
          this.accountInfo.memberPhone = event.target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      }
    },

    // 이메일 형식 검사
    checkEmail() {
      const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.accountInfo.memberEmail?.length > 0) {
        this.validMemberEmail = !emailPattern.test(
          this.accountInfo.memberEmail
        );
      } else {
        this.validMemberEmail = false;
      }
    },

    onCloseModal() {
      this.showInstitutionAccountUpdateModal = false;
    },
    onInstitutionPasswordCloseModal() {
      this.showInstitutionPasswordModal = false;
    },
    closedInstitutionPasswordModal() {
      this.newPassword = '';
    },
    onChangePassword() {
      let change = confirm('해당 기관의 비밀번호를 초기화 하시겠습니까?');
      if (change === true) {
        this.changePassword();
      } else {
        return;
      }
    },

    beforeOpenInstitutionAccountUpdateModal() {
      this.accountInfo = {
        ...this?.$vfm?.get('institutionAccountUpdateModal')?.[0]?.params?._value
          ?.accountInfo,
      };
    },

    closedInstitutionAccountUpdateModal() {
      this.accountInfo = {};
    },

    async updateInstitutionAccount() {
      this.isLoading = true;
      await InstitutionService.updateInstitutionAccount(
        this.accountInfo.memberId,
        this.accountInfo.memberPhone,
        this.accountInfo.memberEmail
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('계정 정보가 수정되었습니다.');
            this.onCloseModal();
            this.getAccountInfo();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async changePassword() {
      this.isLoading = true;
      await InstitutionService.changePassword(this.accountInfo.memberId)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.newPassword = result.data.data.newPassword;
            this.$vfm.show('institutionPasswordModal');
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
