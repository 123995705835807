import http from '/axiosconfig';

class InstitutionService {
  getInstitutionList(page, status, searchValue) {
    if (status === 'n99') {
      return http.get(
        `/v1/institution/_find?page=${encodeURIComponent(
          page
        )}&status=&searchType=name&searchValue=${encodeURIComponent(
          searchValue
        )}`
      );
    } else {
      return http.get(
        `/v1/institution/_find?page=${encodeURIComponent(
          page
        )}&status=${encodeURIComponent(
          status
        )}&searchType=name&searchValue=${encodeURIComponent(searchValue)}`
      );
    }
  }

  getInstitutionInfo(institutionId) {
    return http.get(`/v1/institution/${institutionId}/_findone`);
  }
  getAccountInfo(institutionId) {
    return http.get(`/v1/institution/account/${institutionId}/_findone`);
  }
  getHistoryExamination(institutionId, page) {
    return http.get(
      `/v1/institution/history/examination/_find?institutionId=${institutionId}&page=${page}`
    );
  }
  getHistoryHospital(institutionId, page) {
    return http.get(
      `/v1/institution/history/hospital-link/_find?institutionId=${institutionId}&page=${page}`
    );
  }

  createInstitution(institutionPayload) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/_create`;
    const data = institutionPayload;

    return http.post(url, data);
  }
  changePassword(memberId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/account/_reset-password`;
    const data = { institutionMemberId: memberId };

    return http.post(url, data);
  }

  updateInstitution(
    id,
    name,
    postNumber,
    streetAddress,
    detailAddress,
    phone,
    email,
    status
  ) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/_update`;
    const data = {
      id: id,
      name: name,
      postNumber: postNumber,
      streetAddress: streetAddress,
      detailAddress: detailAddress,
      phone: phone,
      email: email,
      status: status,
    };

    return http.post(url, data);
  }

  updateInstitutionAccount(memberId, memberPhone, memberEmail) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/account/_update`;
    const data = {
      memberId: memberId,
      memberPhone: memberPhone,
      memberEmail: memberEmail,
    };

    return http.post(url, data);
  }
}

export default new InstitutionService();
