<template>
  <div>
    <vue-final-modal
      v-model="showCheckupCreateModal"
      @closed="closedCheckupCreateModal"
      name="showCheckupCreateModal"
      classes="modal-container"
      escToClose
      content-class="modal-content"
    >
      <div class="bg-[#ffffff] w-full">
        <div
          class="h-[56px] bg-[#373943] flex items-center px-[24px] sticky top-0 z-[20] justify-between"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">예약 등록</p>
          <button @click="hideInstitutionModal">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>

        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flxe-col w-[452px] bg-white rounded-[12px] px-[32px] py-[20px]"
          >
            <div class="flex flex-col w-full space-y-[20px]">
              <div v-show="hospitalName === ''">
                <div class="flex items-center">
                  <p class="w-[70px]">기관</p>
                  <div
                    @click="onInstitutionSearch"
                    class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer"
                  >
                    <p class="text-[14px] font-[400] text-[#B6B9C3]">기관명</p>
                  </div>
                  <button
                    @click="onInstitutionSearch"
                    class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                  >
                    <p
                      class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                    >
                      검색
                    </p>
                  </button>
                </div>
              </div>

              <div v-show="hospitalName !== ''">
                <div class="flex items-center mb-[20px]">
                  <p class="w-[70px]">기관</p>

                  <input
                    disabled
                    class="h-[46px] text-[#B6B9C3] w-[213px] border-[1px] rounded-[8px] px-[20px] placeholder:text-[14px]"
                    id="searchValue"
                    type="text"
                    v-model="institutionName"
                    autocomplete="off"
                    placeholder="기관명"
                  />
                  <button
                    @click="onInstitutionSearch"
                    class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                  >
                    <p
                      class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                    >
                      검색
                    </p>
                  </button>
                </div>
                <div class="flex items-center mb-[20px]">
                  <p class="w-[70px]">병원</p>

                  <input
                    class="h-[46px] w-[311px] border-[1px] rounded-[8px] px-[20px] placeholder:text-[14px]"
                    id="hospitalName"
                    type="text"
                    v-model="hospitalName"
                    autocomplete="off"
                    placeholder="병원명"
                    disabled
                  />
                </div>
                <div class="flex w-full items-center mb-[20px]">
                  <p class="w-[70px]">검진일</p>

                  <div class="flex flex-col w-[311px]">
                    <input
                      class="h-[46px] border-[1px] rounded-[8px] px-[20px] placeholder:text-[14px]"
                      id="checkupDate"
                      type="text"
                      v-model="checkupDate"
                      autocomplete="off"
                      placeholder="선택"
                      disabled
                    />
                  </div>
                </div>
                <div class="flex w-full items-center mb-[20px]">
                  <p class="w-[70px]">검진과목</p>

                  <div class="flex flex-col w-[311px]">
                    <input
                      class="h-[46px] border-[1px] rounded-[8px] px-[20px] placeholder:text-[14px]"
                      id="checkupSubject"
                      type="text"
                      v-model="checkupSubject"
                      autocomplete="off"
                      placeholder="선택"
                      disabled
                    />
                  </div>
                </div>
                <div class="flex w-full items-center mb-[20px]">
                  <p class="w-[70px]">인원</p>

                  <div class="flex flex-col w-[311px]">
                    <input
                      class="h-[46px] border-[1px] rounded-[8px] px-[20px] placeholder:text-[14px]"
                      id="checkupCount"
                      type="text"
                      v-model="checkupCount"
                      autocomplete="off"
                      placeholder="인원을 입력해주세요"
                    />
                  </div>
                </div>

                <div class="flex justify-center">
                  <VDatePicker
                    expanded
                    v-model="date"
                    mode="date"
                    :attributes="attributes"
                    @did-move="onDidMoveHandler"
                    :masks="masks"
                    @dayclick="onDayClickHandler"
                    :disabled-dates="disabledDates"
                  >
                    <template #day-popover="{ dayTitle, attributes }">
                      <div class="px-3 py-2">
                        <div
                          class="text-gray-700 dark:text-gray-300 font-semibold text-center mb-1 text-[14px]"
                        >
                          {{ dayTitle }}
                        </div>
                        <ul>
                          <li
                            v-for="{ key, customData } in attributes"
                            :key="key"
                            class="block text-gray-700 dark:text-gray-300 text-[14px]"
                          >
                            <div>
                              <p>
                                <span
                                  class="inline-block w-[8px] h-[8px] rounded-[50%]"
                                  :class="'bg-[#9333EA]'"
                                />
                                {{ customData.startTime }}
                                <span v-if="customData.startTime">~</span>
                                {{ customData.endTime }}
                              </p>
                              <p>
                                {{ customData.hospitalName }} |
                                {{ customData.subject }}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </VDatePicker>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="hideInstitutionModal"
              >
                취소
              </button>

              <button
                v-if="
                  institutionId !== '' &&
                  scheduleId !== undefined &&
                  checkupCount !== null &&
                  checkupDate !== null &&
                  checkupSubject !== null
                "
                @click="onCreateReservation"
                class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              >
                등록
              </button>

              <button
                v-else
                class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>

    <!-- 기관검색 모달 -->
    <vue-final-modal
      name="showInstitutionSearchModal"
      v-model="showInstitutionSearchModal"
      @closed="closedInstitutionSearchModal"
      classes="modal-container"
      content-class="modal-content"
      escToClose
    >
      <div class="bg-[#ffffff] w-[70vw] h-full overflow-auto scrollbar-hide">
        <div
          class="h-[56px] bg-[#373943] flex items-center px-[24px] sticky top-0 z-[20] justify-between"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">기관 검색</p>
          <button @click="hideInstitutionSearchModal">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>

        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flex-col w-full bg-white rounded-[12px] px-[20px] py-[53px]"
          >
            <div class="flex justify-between mb-[24px]">
              <div class="flex">
                <input
                  className="h-[46px] w-[15.6vw] border-[1px] rounded-[8px] px-[20px]  placeholder:text-[14px]"
                  id="institutionName"
                  type="text"
                  v-model="institutionName"
                  autocomplete="off"
                  placeholder="기관명"
                  @keyup.enter="
                    getInstitutionList(
                      this.institutionPage,
                      this.institutionName
                    )
                  "
                />

                <button
                  @click="
                    getInstitutionList(
                      this.institutionPage,
                      this.institutionName
                    )
                  "
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
                >
                  <p
                    class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                  >
                    검색
                  </p>
                </button>
              </div>
            </div>
            <LoadingSpinner v-if="isLoading"></LoadingSpinner>
              <div v-else class="min-h-[70vh]">
              <table class="bg-[#FAFAFB] rounded-[12px] w-full">
                <thead class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in headerList"
                      :key="index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ headerList[index] }}</p>
                        <div
                          class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="institutionList?.pages?.length !== 0">
                  <tr
                    v-for="(institutionItem, index) in institutionList.pages"
                    :key="index"
                  >
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{
                          institutionItem.status === 'ACTIVE'
                          ? '정상'
                          : institutionItem.status === 'DISABLED'
                            ? '종료'
                            : ''
                        }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ institutionItem.name ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] max-w-[250px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ institutionItem.streetAddress ?? '-' }}
                        {{ institutionItem.detailAddress ?? '' }}
                   
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ institutionItem.date ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ institutionItem.hospitalData.name ?? '-' }}
                      </p>
                    </td>

                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <div class="w-full flex justify-center items-center">
                        <p
                          v-if="institutionItem.hospitalData.id"
                          @click="
                            saveInstitutionValue(
                              institutionItem.id,
                              institutionItem.name,
                              institutionItem.hospitalData.id,
                              institutionItem.hospitalData.name
                            )
                          "
                          class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-full hover:bg-[#DDDFE3] whitespace-nowrap"
                        >
                          선택
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div
                  v-else
                  class="w-full h-[50vh] flex items-center justify-center select-none"
                >
                  <div
                    class="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>일치하는 검색 결과가 없습니다.</p>
                  </div>
                </div>
              </table>
              </div>
          </div>
          <div class="w-full mt-[36px] flex justify-center items-center">
            <pagination
              v-model="institutionPage"
              :records="
                institutionList?.totalElementCount
                  ? Number(institutionList?.totalElementCount)
                  : 1
              "
              :per-page="15"
              @paginate="paginate"
            />
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import CheckupService from '@/services/CheckupService';
import InstitutionService from '@/services/InstitutionService';

import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useToast } from 'vue-toastification';
import { ref } from 'vue';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import Pagination from 'v-pagination-3';

const disabledDates = ref([{ start: null, end: new Date() }]);
const masks = ref({
  title: 'YYYY.MM',
  dayPopover: 'YYYY.MM.DD',
});

export default {
  name: 'showCheckupModal',
  components: {
    Pagination,
    LoadingSpinner,
  },
  props: { getCheckupList: Function },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    checkupDate: null,
    checkupSubject: null,
    checkupCount: null,

    disabledDates: disabledDates,
    hospitalId: '',
    yearMonth: format(new Date(), 'yyyy-MM'),
    masks: masks,
    attributes: [],
    format,
    ko,
    parseISO,
    date: ref(new Date()),
    isLoading: false,
    page: 1,
    institutionPage: 1,
    totalPages: 1,
    currentElementCount: 1,
    showModal: false,
    modalType: '',

    reservationList: {},

    showCheckupCreateModal: false,
    institutionPossibility: false,
    institutionValue: '',
    institutionId: '',
    scheduleId: '',

    showInstitutionSearchModal: false,
    busPossibility: false,
    hospitalName: '',

    headerList: ['상태', '기관', '주소', '등록일', '연결병원', '선택'],

    institutionName: '',
    institutionList: {},
    searchValue: '',
  }),

  methods: {
    paginate() {
      this.onInstitutionSearch();
    },

    closedInstitutionSearchModal() {
      this.searchValue = '';
    },
    closedCheckupCreateModal() {
      this.resetData();
    },

    onCreateReservation() {
      this.createReservation(
        this.institutionId,
        this.scheduleId,
        this.checkupCount
      );
    },
    onDayClickHandler(calendarInfo) {
      if (calendarInfo.date < new Date()) {
        return;
      }
      // 스케쥴 있는 경우
      const isSchedule = this?.attributes?.find(
        (item) => format(item?.dates, 'yyyy-MM-dd') == calendarInfo.id
      );

      if (isSchedule === undefined) {
        this.checkupDate = null;
        this.checkupSubject = null;
      } else if (isSchedule !== undefined) {
        this.checkupDate = `${format(calendarInfo?.date, 'yyyy. MM. dd (eee)', {
          locale: ko,
        })} ${isSchedule?.customData?.startTime} ~ ${
          isSchedule?.customData?.endTime
        }`;
        this.checkupSubject = isSchedule?.customData?.subject;
        this.scheduleId = isSchedule?.customData?.scheduleId;
      }
    },
    onDidMoveHandler(calendarInfo) {
      this.yearMonth = calendarInfo[0].id;
      this.getReservation();
    },

    async scheduleDot() {
      if (this?.reservationList.length > 0) {
        this.reservationList.map((item) => {
          const purpleItem = {
            dates: parseISO(item.availableDate),
            dot: {
              color: 'purple',
            },
            popover: {
              visibility: 'hover',
            },
            customData: {
              hospitalName: item.hospitalName,
              subject: item.subject,
              startTime: item.startTime,
              endTime: item.endTime,
              scheduleId: item.scheduleId,
            },
          };
          this.attributes.push(purpleItem);
        });
      }
    },

    onInstitutionSearch() {
      this.getInstitutionList(this.institutionPage, this.searchValue);
      this.resetData();
      this.$vfm.show('showInstitutionSearchModal');
    },

    resetData() {
      this.institutionValue = '';
      this.hospitalName = '';

      this.searchValue = '';

      this.institutionName = '';
      this.institutionList = {};
      this.institutionId === '';
      this.scheduleId === '';

      this.checkupDate = null;
      this.checkupSubject = null;
      this.checkupCount = null;
      this.hospitalId = '';
      this.yearMonth = format(new Date(), 'yyyy-MM');
      this.attributes = [];
    },

    hideModal() {
      this.showModal = false;
      this.institutionValue = '';
      this.hospitalName = '';
    },

    hideInstitutionModal() {
      this.institutionValue = '';
      this.hospitalName = '';
      this.hospitalId = '';
      this.institutionName = '';
      this.attributes = [];
      this.$vfm.hide('showCheckupCreateModal');
    },

    hideInstitutionSearchModal() {
      this.$vfm.hide('showInstitutionSearchModal');
    },

    showCheckupCreateCalendarModal() {
      this.$vfm.show('showCheckupCreateCalendarModal');
    },

    saveInstitutionValue(
      institutionId,
      institutionName,
      hospitalId,
      hospitalName
    ) {
      this.institutionId = institutionId;
      this.institutionName = institutionName;
      this.hospitalId = hospitalId;
      this.hospitalName = hospitalName;

      this.getReservation();
      this.$vfm.hide('showInstitutionSearchModal');
    },

    async getInstitutionList(page, institutionValue) {
      this.isLoading = true;

      await InstitutionService.getInstitutionList(
        page,
        'ACTIVE',
        institutionValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.institutionList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getInstitutionListError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getReservation() {
      this.isLoading = true;
      this.attributes = [];
      await CheckupService.getReservation(
        this.yearMonth,
        this.hospitalId,
        this.institutionId
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.reservationList = result.data.data;
            this.scheduleDot();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getReservationError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    async createReservation(institutionId, scheduleId, count) {
      this.isLoading = true;
      await CheckupService.createReservation(institutionId, scheduleId, count)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('예약 등록 완료');
            this.getCheckupList();
            this.hideInstitutionModal();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getReservationError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
