import http from '/axiosconfig';

class BusService {
  getBusList(page, status, searchType, searchValue) {
    if (status === 'n99') {
      return http.get(
        `/v1/bus/_find?page=${page}&status=&searchType=${encodeURIComponent(
          searchType
        )}&searchValue=${encodeURIComponent(searchValue)}`
      );
    } else {
      return http.get(
        `/v1/bus/_find?page=${page}&status=${encodeURIComponent(
          status
        )}&searchType=${encodeURIComponent(
          searchType
        )}&searchValue=${encodeURIComponent(searchValue)}`
      );
    }
  }
  getBusInfo(busId) {
    return http.get(`/v1/bus/${busId}/_findone`);
  }

  getBusExamination(busId, page) {
    return http.get(
      `/v1/bus/history/examination/_find?busId=${busId}&page=${page}`
    );
  }
  getBusHospitalLink(busId, page) {
    return http.get(
      `/v1/bus/history/hospital-link/_find?busId=${busId}&page=${page}`
    );
  }

  createBus(vehicleNumber) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus/_create`;
    const data = {
      vehicleNumber: vehicleNumber,
    };

    return http.post(url, data);
  }

  updateBusInfo(id, vehicleNumber, status) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus/_update`;
    const data = {
      id: id,
      vehicleNumber: vehicleNumber,
      status: status,
    };

    return http.post(url, data);
  }
}

export default new BusService();
