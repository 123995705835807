<template>
  <div
    class="absolute z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-6 h-[full] w-[full] bg-white rounded-xl">
    <div class="flex flex-col justify-center items-center">
      <img class="w-[87px] h-[48px] relative" src="@/assets/images/error_icon.svg" />
      <h2 class="text-[28px] font-[700] leading-[34px] pt-[40px] pb-[8px]">페이지에 오류가 발생했습니다.</h2>
      <p class="pb-[32px]">다시 접속을 시도해주세요.</p>
      <button class="bg-[#AB3B94] w-[148px] h-[46px] text-[white] rounded-[8px] font-[600] hover:bg-[#7a2a6a]"
        @click="replacePage">
        이전 페이지</button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'ServerError',

  data() {
    document.title = 'ONIONE | Internal Server Error';
    return {};
  },
  methods: {
    replacePage(e) {
      e.preventDefault();
      this.$router.replace('/login');
    }
  },
  components: {},
};
</script>
