<template>
  <InstitutionCreateModal :getInstitutionList="getInstitutionList" />
  <InstitutionDetailModal :getInstitutionList="getInstitutionList" />

  <div class="flex justify-between pb-[38px]">
    <h1 class="text-[30px] font-[700] text-left ]">기관 목록</h1>

    <button
      @click="onShowInstitutionCreateModal"
      class="bg-[#AB3B94] hover:bg-[#b34e9e] flex items-center justify-center space-x-[12px] cursor-pointer px-[20px] rounded-[8px]"
    >
      <img
        class="w-[18px] h-[18px] relative"
        src="@/assets/images/common_plus.svg"
      />
      <p
        class="text-white text-[14px] font-[600] flex items-center justify-center"
      >
        기관 등록
      </p>
    </button>
  </div>
  <div
    class="h-20% bg-white rounded-[12px] mb-[16px] flex px-[32px] py-[38px] space-x-[10px]"
  >
    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">상태</p>

      <select
        v-model="status"
        :value="status"
        class="bg-white border-[1px] border-onione-N200 select-none items-center px-[16px] h-[46px] w-[124px] rounded-[8px] text-[14px] text-onione-N600"
      >
        <option
          v-for="(item, index) in statusSelect"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </option>
      </select>
    </div>

    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">검색</p>

      <div class="flex">
        <span
          class="text-onione-N600 text-[14px] font-[500] select-none whitespace-nowrap w-[124px] flex items-center border-onione-N200 border-[1px] pl-[16px] border-r-0 rounded-tl-[8px] rounded-bl-[8px]"
          >기관명</span
        >

        <input
          className="h-[46px] w-[15.6vw] border-onione-N200 border-[1px] rounded-tr-[8px]  rounded-br-[8px] px-[20px]  placeholder:text-[14px] text-[14px]"
          id="searchValue"
          type="text"
          v-model="searchValue"
          autocomplete="off"
          placeholder="검색어"
          @keyup.enter="onSearch"
        />

        <button
          @click="onSearch"
          class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
        >
          <p
            class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            검색
          </p>
        </button>

        <button
          @click="onResetFilter"
          class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer w-[88px] rounded-[8px] border border-onione-N200"
        >
          <p
            class="text-[#65697B] text-[14px] font-[400] flex items-center justify-center whitespace-nowrap"
          >
            초기화
          </p>
        </button>
      </div>
    </div>
  </div>

  <div class="w-full bg-white rounded-[12px]">
    <LoadingSpinner v-if="isLoading"></LoadingSpinner>
    <div v-else>
      <table class="bg-[#FAFAFB] rounded-[12px] w-full">
        <thead class="bg-[#EBECEF]">
          <tr class="rounded-t-[12px]">
            <th
              class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
              v-for="(column, index) in headerList"
              :key="index"
            >
              <div
                class="absolute right-[0px] top-[calc(50%-8px)] h-[16px] bg-onione-N200"
                :class="[
                  index === headerList.length - 1 ? 'w-[0px]' : 'w-[1px]',
                ]"
              />
              <div class="flex items-center justify-center">
                <p class="font-[600]">{{ headerList[index] }}</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="institutionList?.pages?.length !== 0">
          <tr
            v-for="(institutionItem, index) in institutionList.pages"
            :key="index"
          >
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[8%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.no ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[5%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.status === 'ACTIVE' ? '정상' : '종료' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[15%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.name ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[10%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.memberAccountName ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] max-w-[250px] w-[25%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.streetAddress ?? '-' }}
                {{ institutionItem.detailAddress ?? '' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[15%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.phone ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[10%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center text-center"
              >
                {{ institutionItem.hospitalData.name ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] justify-center"
            >
              <div class="flex justify-center">
                <button
                  @click="onShowInstitutionDetailModal(institutionItem.id)"
                  class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer whitespace-nowrap w-[60px]"
                >
                  보기
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <div
          v-else
          class="w-full h-[70vh] flex items-center justify-center select-none"
        >
          <div
            class="absolute left-[50%] flex flex-col items-center space-y-[24px]"
          >
            <img
              class="w-[48px] h-[48px] relative"
              src="@/assets/images/common_notice.svg"
            />
            <p>일치하는 검색 결과가 없습니다.</p>
          </div>
        </div>
      </table>
    </div>
    <div class="w-full pt-[24px] pb-[40px] flex justify-center">
      <pagination
        v-model="page"
        :records="
          institutionList.totalElementCount
            ? Number(institutionList?.totalElementCount)
            : 1
        "
        :per-page="15"
        @paginate="paginate"
      />
    </div>
  </div>
</template>

<script>
import InstitutionDetailModal from '@/components/modal/InstitutionDetailModal';
import InstitutionCreateModal from '@/components/modal/InstitutionCreateModal';
import InstitutionService from '@/services/InstitutionService';
import Pagination from 'v-pagination-3';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { useToast } from 'vue-toastification';

export default {
  name: 'InstitutionPage',
  components: {
    InstitutionDetailModal,
    Pagination,
    LoadingSpinner,
    InstitutionCreateModal,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    document.title = 'ONIONE | 기관목록';
    return {
      isLoading: false,
      format,
      ko,
      parseISO,
      status: 'n99',
      statusSelect: [
        { name: '전체', value: 'n99' },
        { name: '정상', value: 'ACTIVE' },
        { name: '종료', value: 'DISABLED' },
      ],
      searchValue: '',
      headerList: [
        'No.',
        '상태',
        '기관',
        '아이디',
        '주소',
        '연락처',
        '담당병원',
        '상세',
      ],
      institutionList: {},
      tab: '검진 내역',
      page: 1,
      totalPages: 1,
      currentElementCount: 1,
    };
  },

  mounted() {
    this.getInstitutionList();
  },

  methods: {
    onSearch() {
      if (!(this.searchValue.length < 31)) {
        this.toast.error('검색어는 30자 이하로 입력해주세요.', {
          bodyClassName: ['custom-class-1', 'custom-class-2'],
        });
        return;
      }

      this.getInstitutionList();
    },

    onResetFilter() {
      this.status = 'n99';
      this.searchValue = '';
      this.page = 1;
      this.getInstitutionList();
    },

    onShowInstitutionCreateModal() {
      this.$vfm.show('institutionCreateModal');
    },

    paginate() {
      this.getInstitutionList();
    },

    onShowInstitutionDetailModal(institutionId) {
      this.$vfm.show('institutionDetailModal', {
        institutionId: institutionId,
      });
    },

    async getInstitutionList() {
      this.isLoading = true;
      await InstitutionService.getInstitutionList(
        this.page,
        this.status,
        this.searchValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.institutionList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
