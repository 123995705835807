import http from "/axiosconfig";

class ReservationService {
  getReservationList(
    page,
    dateType,
    startDate,
    endDate,
    searchType,
    searchValue
  ) {
    return http.get(
      `/v1/institution/reservation/manager/_find?page=${page}&dateType=${encodeURIComponent(
        dateType
      )}&startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}&searchType=${encodeURIComponent(
        searchType
      )}&searchValue=${encodeURIComponent(searchValue)}
      `
    );
  }

  ReservationManagement(reservationId, status) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/reservation/status/_update`;
    const data = {
      reservationId: reservationId,
      status: status,
    };

    return http.post(url, data);
  }
}
export default new ReservationService();
