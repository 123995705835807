import http from '/axiosconfig';
// import router from '@/router';
class HospitalService {
  getHospitalList(page, status, searchValue) {
    if (status === 'n99') {
      return http.get(
        `/v1/bus-hospital/_find?page=${encodeURIComponent(
          page
        )}&status=&searchType=name&searchValue=${encodeURIComponent(
          searchValue
        )}`
      );
    } else {
      return http.get(
        `/v1/bus-hospital/_find?page=${encodeURIComponent(
          page
        )}&status=${encodeURIComponent(
          status
        )}&searchType=name&searchValue=${encodeURIComponent(searchValue)}`
      );
    }
  }

  getHospitalInfo(hospitalId) {
    return http.get(`/v1/bus-hospital/${hospitalId}/_findone`);
  }
  getHospitalSchedule(hospitalId, month) {
    return http.get(
      `/v1/bus-hospital/schedule/_find?hospitalId=${hospitalId}&yearMonth=${month}`
    );
  }

  getExamination(hospitalId, page) {
    return http.get(
      `/v1/bus-hospital/history/examination/_find?hospitalId=${hospitalId}&page=${page}`
    );
  }

  createHospital(
    name,
    postNumber,
    streetAddress,
    detailAddress,
    phone,
    managerPhone
  ) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/_create`;
    const data = {
      name: name,
      postNumber: postNumber,
      streetAddress: streetAddress,
      detailAddress: detailAddress,
      phone: phone,
      managerPhone: managerPhone,
    };

    return http.post(url, data);
  }

  updateHospital(
    id,
    name,
    postNumber,
    streetAddress,
    detailAddress,
    phone,
    managerPhone,
    status
  ) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/_update`;
    const data = {
      id: id,
      name: name,
      postNumber: postNumber,
      streetAddress: streetAddress,
      detailAddress: detailAddress,
      phone: phone,
      managerPhone: managerPhone,
      status: status,
    };

    return http.post(url, data);
  }

  createSchedule(schedule) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/schedule/_create`;
    return http.post(url, schedule);
  }

  updateSchedule(schedule) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/schedule/_update`;
    return http.post(url, schedule);
  }

  deleteSchedule(scheduleId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/schedule/_delete`;
    return http.post(url, scheduleId);
  }
}

export default new HospitalService();
