import axios from 'axios';
import AuthService from '@/services/AuthService';
import router from '@/router';

const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_ROOT_URL,

  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

http.interceptors.request.use(
  async function (config) {
    // 요청 전달 전에 처리
    config.headers.Authorization = `Bearer ${sessionStorage.getItem(
      'accessToken'
    )}`;
    return config;
  },
  function (error) {
    // 요청 오류에 대한 처리
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    // 응답 상태코드가 2xx
    return response;
  },
  async function (error) {
    // 응답 상태코드가 2xx 아닐떄
    const errorAPI = error.config;

    if (error.code === 'ERR_NETWORK') {
      console.error('네트워크 에러가 발생했습니다.');
      alert('네트워크 에러가 발생했습니다. 관리자에게 문의해주세요.');
    } else if (error.code === 'ECONNABORTED') {
      console.error('요청이 제한 시간을 초과했습니다.');
      alert('요청이 제한 시간을 초과했습니다. 관리자에게 문의해주세요.');
    } else {
      if (error.response.status === 401 && errorAPI.retry === undefined) {
        console.log('액세스토큰 재발급 시도');
        errorAPI._retry = true;

        try {
          await AuthService.refreshToken();
          errorAPI.headers.Authorization = `Bearer ${sessionStorage.getItem(
            'accessToken'
          )}`;
          return await axios(errorAPI);
        } catch (error) {
          //리프레시토큰 만료 -> clearTokens 후 로그인 페이지 이동
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('refreshToken');
          router.replace('/login');
        } // catch
      } else if (error.response && error.response.status === 403) {
        console.error('403 에러');
        alert('허용되지 않는 접근입니다. 관리자에게 문의해주세요.');
      } else {
        console.error('알수 없는 서버 에러', error);
        router.replace('/error');
      }
    }

    return Promise.reject(error);
  }
);

export default http;
