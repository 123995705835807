import http from '/axiosconfig';
import axios from 'axios';
import store from '@/store';
class AuthService {
  loginUser(userId, password) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/member/local-branch/_login`;
    const data = {
      username: userId,
      password: password,
    };
    return http.post(url, data);
  }

  logoutUser() {
    return http.post('/v1/member/local-branch/_logout');
  }

  async refreshToken() {
    const config = {
      baseURL: process.env.VUE_APP_BACKEND_ROOT_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      timeout: 15000,
    };
    const url = `/v1/member/local-branch/_token`;
    const refreshToken = sessionStorage.getItem('refreshToken');
    return axios.post(url, { refreshToken }, config).then(async (result) => {
      if (result?.data?.resultCode === 200) {
        //토큰재발급 성공
        sessionStorage.setItem('accessToken', result.data.data.accessToken);
        sessionStorage.setItem('refreshToken', result.data.data.refreshToken);
        await store.getAuthStatusServer();
        return;
      }
    });
  }
}

export default new AuthService();
