<template>
  <vue-final-modal v-model="showModal" name="InstitutionHospitalModifyModal" classes="modal-container" escToClose
    content-class="modal-content">
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
      <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
        <p class="font-[600] text-[#ffffff] text-[17px]">기관-병원 연결 수정</p>
        <button @click="confirm">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]">

          <div class="flex flex-col w-full space-y-[46px]">
            <div class="flex items-center">
              <p class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[10%] whitespace-nowrap">
                기관
              </p>
              <div class="w-[90%] h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center bg-[#FAFAFB]">
                <p class="text-[14px] font-[400] text-[#7E8295]"> {{ institutionName }}</p>
              </div>
            </div>

            <div v-if="institutionName !== ''" class="flex items-center w-full">
              <p class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[10%] whitespace-nowrap">
                병원
              </p>

              <div class="w-[90%] flex">
                <div @click="showHospitalLinkCreateModal = true"
                  class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer">
                  <p class="text-[14px] font-[400] text-[#B6B9C3]" v-if="busHospitalName === ''"> 차량번호</p>
                  <p class="text-[14px] font-[400] text-[#202127]" v-else> {{ busHospitalName }}</p>
                </div>

                <button @click="showHospitalLinkCreateModal = true"
                  class=" bg-[#65697B] h-[46px] ml-[10px] flex items-center justify-center space-x-[12px]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     cursor-pointer px-[31px] rounded-[8px]">
                  <p class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                    검색
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="confirm">취소</button>

            <button v-if="busHospitalName !== '' && institutionName !== '' && !isLoading" @click="updateLink"
              class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer'>
              수정
            </button>

            <button v-else
              class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]'>
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
  <!-- 기관선택 모달 -->
  <vue-final-modal v-model="showHospitalLinkCreateModal" classes="modal-container" content-class="modal-content"
    escToClose>
    <div class="bg-[#ffffff] w-[70vw] h-full overflow-auto scrollbar-hide">

      <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
        <p class="font-[600] text-[#ffffff] text-[17px]">병원 검색 </p>
        <button @click="onClose('showHospitalLinkCreateModal')">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>

      <div class="bg-[#EEF0F4] p-[24px]">
        <div class="flex flex-col w-full bg-white rounded-[12px] px-[32px] py-[53px]">
          <div class="flex justify-between mb-[24px]">
            <div class="flex items-center justify-center">
              <input id='modify_bus' type='checkbox' v-model="bushospitalPossibility"
                @change="getHospitalList(1, this.bushospitalPossibility, this.searchValue); this.page = 1">
              <label htmlFor='modify_bus'
                class="flex items-center justify-center exst-[14px] ml-[10px] text-[#373943] font-[500] select-none">
                연결 가능한 병원만 보기
              </label>
            </div>
            <div class="flex">
              <input className="h-[46px] w-[15.6vw] border-[1px] rounded-[8px] px-[20px]  placeholder:text-[14px]"
                id="searchValue" type="text" v-model="searchValue" autocomplete="off" placeholder="병원명"
                @keyup.enter="getHospitalList(1, this.bushospitalPossibility, this.searchValue); this.page = 1" />

              <button @click="getHospitalList(1, this.bushospitalPossibility, this.searchValue); this.page = 1"
                class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]">
                <p class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                  검색
                </p>
              </button>

              <button @click="resetBusFilter"
                class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-[#DDDFE3]">
                <p class="text-[#65697B] text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                  초기화
                </p>
              </button>
            </div>
          </div>
          <LoadingSpinner v-if="isLoading"></LoadingSpinner>
          <div v-else class="min-h-[70vh]">
            <table class="bg-[#FAFAFB] rounded-[12px] w-full">
              <thead class="bg-[#EBECEF]">
                <tr class="rounded-t-[12px]">
                  <th
                    class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                    v-for="(column, index) in headerList" :key="index">
                    <div class="flex items-center justify-center">
                      <p class="font-[600]">{{ headerList[index] }}</p>
                      <div class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="hospitalList?.pages?.length !== 0">
                <tr v-for="(item, index) in hospitalList.pages" :key="index">

                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                      {{
                        item.status === "ACTIVE"
                        ? "정상"
                        : item.status === "DISABLED"
                          ? "종료"
                          : ""
                      }}
                    </p>
                  </td>

                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                      {{ item.name ?? "-" }}
                    </p>
                  </td>
                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                      {{ item.streetAddress ?? "-" }} {{ item.detailAddress ?? "" }}
                    </p>
                  </td>

                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                      {{ item.createdDate ?? "-" }}
                    </p>
                  </td>

                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                      {{ item.vehicleNumber ?? "-" }}
                    </p>
                  </td>

                  <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                    <div class="w-full flex justify-center items-center">
                      <p @click="savebusHospitalName(item.name, item.busHospitalId)" v-if="item.possibility === true"
                        class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-full hover:bg-[#DDDFE3]  whitespace-nowrap">
                        선택
                      </p>
                      <p v-else>-</p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <div v-else class="w-full h-[50vh]  flex items-center justify-center select-none">
                <div class="absolute left-1/2 transform -translate-x-1/2  flex flex-col items-center space-y-[24px]">
                  <img class="w-[48px] h-[48px] relative" src="@/assets/images/common_notice.svg" />
                  <p>일치하는 검색 결과가 없습니다.</p>
                </div>
              </div>
            </table>
          </div>
        </div>
        <div class="w-full mt-[36px] flex justify-center items-center">
          <pagination v-model="page" :records="busList?.totalElementCount ? Number(busList?.totalElementCount) : 1
          " :per-page="15" @paginate="buspaginate" />
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import InstitutionHospitalService from "@/services/InstitutionHospitalService";
import Pagination from "v-pagination-3";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import { useToast } from "vue-toastification";

export default {
  name: "InstitutionHospitalModifyModal",
  components: {
    Pagination,
    LoadingSpinner
  },

  props: {
    modify: Function
  },

  setup() {
    const toast = useToast();
    return { toast }
  },


  data: () => ({
    isLoading: false,

    page: 1,
    totalPages: 1,
    currentElementCount: 1,

    showModal: false,
    institutionId: '',
    institutionName: '',


    showHospitalLinkCreateModal: false,
    bushospitalPossibility: false,
    busHospitalId: "",
    busHospitalName: "",


    headerList: [
      "상태",
      "병원",
      "주소",
      "등록일",
      "연결버스",
      "선택",
    ],
    searchValue: "",
    hospitalList: {},
  }),


  beforeUpdate() {
    if (this.institutionName === '' && this.busHospitalName === '') {
      this.institutionId =
        this?.$vfm?.get('InstitutionHospitalModifyModal')?.[0]?.params?._value?.institutionId;
      this.institutionName =
        this?.$vfm?.get('InstitutionHospitalModifyModal')?.[0]?.params?._value?.institutionName;
      this.busHospitalId =
        this?.$vfm?.get('InstitutionHospitalModifyModal')?.[0]?.params?._value?.busHospitalId;
      this.busHospitalName =
        this?.$vfm?.get('InstitutionHospitalModifyModal')?.[0]?.params?._value?.busHospitalName;
    }
  },

  updated() {
    if (this.showModal === false) {
      this.institutionId = ''
      this.busHospitalId = ''
      this.institutionName = ''
      this.busHospitalName = ''
    }

    if (this.showHospitalLinkCreateModal === true) {
      this.getHospitalList(this.page, this.bushospitalPossibility, this.searchValue);
    }

    if (this.showHospitalLinkCreateModal === false) {
      this.page = 1
      this.bushospitalPossibility = false
      this.searchValue = ''
    }
  },

  methods: {
    onClose(modal) {
      this[modal] = false;
    },
    confirm() {
      this.institutionId = ''
      this.busHospitalId = ''
      this.institutionName = ''
      this.busHospitalName = ''
      this.showModal = false
    },

    buspaginate() {
      this.getHospitalList(this.page, this.bushospitalPossibility, this.searchValue);
    },

    savebusHospitalName(busHospitalName, busHospitalId) {
      this.busHospitalName = busHospitalName;
      this.busHospitalId = busHospitalId;
      this.searchValue = ''
      this.showHospitalLinkCreateModal = false;
      this.page = 1
      this.bushospitalPossibility = false
      this.searchValue = ''
    },

    resetBusFilter() {
      this.bushospitalPossibility = false;
      this.searchValue = ''
      this.getHospitalList(1, this.bushospitalPossibility, this.searchValue);
      this.page = 1;
    },


    async getHospitalList(page, status, institutionName) {
      this.isLoading = true;

      await InstitutionHospitalService.getHospitalList(page, status, institutionName)
        .then((result) => {

          if (result?.data?.resultCode === 200) {
            this.hospitalList = result.data.data;
          }

          else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message)
          }
        })
        .catch((error) => console.error("getHospitalListError", error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateLink() {
      this.isLoading = true;
      await InstitutionHospitalService.updateLink(this.institutionId, this.busHospitalId)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success("기관 - 병원 연결 정보가 수정되었습니다.");
            this.$emit('modify', 1, '', '')
            this.showModal = false;
          }

          else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message)
          }
        })
        .catch((error) => console.error("getHospitalError", error))
        .finally(() => {
          setTimeout(() => { this.isLoading = false; }, 1000)
        });
    },

  },

};
</script>

<style></style>
