<template>
  <vue-final-modal v-model="showModal" name="ReservationModal" classes="modal-container" content-class="modal-content">
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
          <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
            <p v-if="modalType === '승인'" class="font-[600] text-[#ffffff] text-[17px]">예약 승인</p>
            <p v-else class="font-[600] text-[#ffffff] text-[17px]">예약 반려</p>
            <button @click="confirm">
              <img src="@/assets/images/modal_close_icon.svg" />
            </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div class="w-[30vw] bg-white py-[40px] rounded-[12px] items-center justify-center">
          <p v-if="modalType === '승인'"
            class="text-[14px] text-[#373943] font-[700] w-full flex items-center justify-center">
            해당 예약신청을 승인하시겠습니까?
          </p>
          <p v-else class="text-[14px] text-[#373943] font-[700] w-full flex items-center justify-center">
            해당 예약신청을 반려하시겠습니까?
          </p>
        </div>
          <div v-if="modalType === '승인' && !isLoading" class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="confirm">취소</button>
              <button @click="ReservationManagement(this.reservationId, 'CONFIRM')"
                class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer'>
                승인
              </button>
            </div>
          </div>

          <div v-if="modalType === '반려' && !isLoading" class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="confirm">취소</button>
            <button @click="ReservationManagement(this.reservationId, 'CANCEL')"
              class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer'>
              반려
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import ReservationService from "@/services/ReservationService";
import { useToast } from "vue-toastification";

export default {
  name: "ReservationModal",
  components: {
  },

  props: {
    update: Function
  },

  setup() {
    const toast = useToast();
    return { toast }
  },


  data: () => ({
    isLoading: false,

    modalType: '',
    showModal: false,

    reservationId: "",
    status: "",

  }),


  beforeUpdate() {
    if (this.reservationId === '') {
      this.modalType =
        this?.$vfm?.get('ReservationModal')?.[0]?.params?._value?.modalType;
      this.reservationId =
        this?.$vfm?.get('ReservationModal')?.[0]?.params?._value?.reservationId;
    }
  },

  updated() {
    if (this.showModal === false) {
      this.reservationId = '';
      //this.modalType = '';
      this.showModal = false;
    }
  },

  methods: {
    confirm() {
      this.reservationId = '';
      this.busId = '';
      this.showModal = false;
    },


    async ReservationManagement(reservationId, status) {
      this.isLoading = true;
      await ReservationService.ReservationManagement(reservationId, status)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            if (this.modalType === '승인') {
              this.toast.success("예약신청이 승인되었습니다.");
              this.$emit('update', 1, 'n99', new Date(), new Date(), '')
              this.showModal = false;
            }

            else if (this.modalType === '반려') {
              this.toast.success("예약신청이 반려되었습니다.");
              this.$emit('update', 1, 'n99', new Date(), new Date(), '')
              this.showModal = false;
            }
          }
          else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message)
          }
        })
        .catch((error) => console.error("ReservationlError", error))
        .finally(() => {
          setTimeout(() => { this.isLoading = false; }, 1000);
        });
    },

  },

};
</script>

<style></style>
