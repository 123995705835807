<template>
  <InstitutionHospitalCreateModal
    v-if="showCreateModal"
    @update="getLinkList"
  />
  <InstitutionHospitalModifyModal
    v-if="showModifyModal"
    @modify="getLinkList"
  />
  <InstitutionHospitalDeleteModal
    v-if="showDeleteModal"
    @delete="getLinkList"
  />
  <div class="flex justify-between pb-[38px]">
    <h1 class="text-[30px] font-[700] text-left ]">연결 관리 > 기관-병원</h1>

    <button
      @click="showCreateModal('신규')"
      class="bg-[#AB3B94] hover:bg-[#b34e9e] flex items-center justify-center space-x-[12px] cursor-pointer px-[20px] rounded-[8px]"
    >
      <img
        class="w-[18px] h-[18px] relative"
        src="@/assets/images/common_plus.svg"
      />
      <p
        class="text-white text-[14px] font-[600] flex items-center justify-center"
      >
        신규 연결
      </p>
    </button>
  </div>

  <div
    class="h-20% bg-white rounded-[12px] mb-[16px] flex px-[32px] py-[38px] space-x-[10px]"
  >
    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">검색</p>

      <div class="flex">
        <select
          v-model="searchType"
          @change="changeSearchType"
          class="min-w-[124px] bg-white border-y-[1px] border-l-[1px] border-onione-N200 select-none items-center px-[16px] rounded-[8px] rounded-r-none text-onione-N600 font-[500] text-[14px]"
        >
          <option
            v-for="(item, index) in searchTypeSelect"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>

        <input
          className="h-[46px] w-[15.6vw] border-[1px] border-onione-N200 rounded-tr-[8px]  rounded-br-[8px] px-[20px] text-onione-N900  placeholder:text-[14px] placeholder:text-onione-N300 text-[14px]"
          id="searchValue"
          type="text"
          v-model="searchValue"
          autocomplete="off"
          placeholder="검색어"
          @keyup.enter="getLinkList"
        />

        <button
          @click="getLinkList"
          class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
        >
          <p
            class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            검색
          </p>
        </button>

        <button
          @click="resetFilter"
          class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-onione-N200 w-[88px]"
        >
          <p
            class="text-[#65697B] text-[14px] font-[400] flex items-center justify-center whitespace-nowrap"
          >
            초기화
          </p>
        </button>
      </div>
    </div>
  </div>

  <!-- <div
    class="h-20% bg-white rounded-[12px] mb-[16px] flex px-[32px] py-[38px] space-x-[10px]"
  >
    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">검색</p>

      <div class="flex w-full">
        <select
          v-model="type"
          :value="type"
          @change="setSelect($event)"
          class="bg-white border border-[#DDDFE3] select-none w-[124px] items-center px-[16px] py-[11.5px] rounded-tl-[8px] rounded-bl-[8px] border-r-[none]"
        >
          <option
            v-for="(item, index) in searchTypeSelect"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>

        <input
          className="h-[46px] w-[15.6vw] border-[1px] rounded-tr-[8px]  rounded-br-[8px] px-[20px]  placeholder:text-[14px]"
          id="searchValue"
          type="text"
          v-model="searchValue"
          autocomplete="off"
          placeholder="검색어"
          @keyup.enter="
            getLinkList(1, this.type, this.searchValue);
            this.page = 1;
          "
        />

        <button
          @click="
            getLinkList(1, this.type, this.searchValue);
            this.page = 1;
          "
          class="bg-[#65697B] hover:bg-[#DDDFE3] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
        >
          <p
            class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            검색
          </p>
        </button>

        <button
          @click="resetFilter"
          class="bg-[#FFFFFF] hover:bg-[#DDDFE3] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-[#DDDFE3]"
        >
          <p
            class="text-[#65697B] text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            초기화
          </p>
        </button>
      </div>
    </div>
  </div> -->

  <div class="w-full bg-white rounded-[12px]">
    <LoadingSpinner v-if="isLoading"></LoadingSpinner>
    <div v-else>
      <table class="bg-[#FAFAFB] rounded-[12px] w-full">
        <thead class="bg-[#EBECEF]">
          <tr class="rounded-t-[12px]">
            <th
              class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
              v-for="(column, index) in headerList"
              :key="index"
            >
              <div class="flex items-center justify-center">
                <p class="font-[600]">{{ headerList[index] }}</p>
                <div
                  class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[-2px] items-center justify-center"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="institutionHospitalList?.pages?.length !== 0">
          <tr
            v-for="(item, index) in institutionHospitalList.pages"
            :key="index"
          >
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[10%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
              >
                {{ item.no }}
              </p>
            </td>

            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[20%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
              >
                {{ item.institutionName ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[20%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
              >
                {{ item.busHospitalName ?? '-' }}
              </p>
            </td>
            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[20%]"
            >
              <p
                class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
              >
                {{ item.connectedDate }}
              </p>
            </td>

            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[10%]"
            >
              <div class="w-full flex justify-center items-center">
                <p
                  v-if="item.possibilityModify === true"
                  @click="
                    showModifyModal(
                      item.institutionId,
                      item.institutionName,
                      item.busHospitalId,
                      item.busHospitalName
                    )
                  "
                  class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-1/2 hover:bg-[#DDDFE3]"
                >
                  수정
                </p>
                <p v-else>-</p>
              </div>
            </td>

            <td
              class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px] w-[10%]"
            >
              <div class="w-full flex justify-center items-center">
                <p
                  v-if="item.possibilityDelete === true"
                  @click="showDeleteModal(item.institutionId)"
                  class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-1/2 hover:bg-[#DDDFE3]"
                >
                  삭제
                </p>
                <p v-else>-</p>
              </div>
            </td>
          </tr>
        </tbody>
        <div
          v-else
          class="w-full h-[70vh] flex items-center justify-center select-none"
        >
          <div
            class="absolute left-[50%] flex flex-col items-center space-y-[24px]"
          >
            <img
              class="w-[48px] h-[48px] relative"
              src="@/assets/images/common_notice.svg"
            />
            <p>일치하는 검색 결과가 없습니다.</p>
          </div>
        </div>
      </table>

      <div class="w-full pt-[24px] pb-[40px] flex justify-center">
        <pagination
          v-model="page"
          :records="
            institutionHospitalList.totalElementCount
              ? Number(institutionHospitalList?.totalElementCount)
              : 1
          "
          :per-page="15"
          @paginate="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InstitutionHospitalCreateModal from '@/components/modal/InstitutionHospitalCreateModal';
import InstitutionHospitalModifyModal from '@/components/modal/InstitutionHospitalModifyModal';
import InstitutionHospitalDeleteModal from '@/components/modal/InstitutionHospitalDeleteModal';
import InstitutionHospitalService from '@/services/InstitutionHospitalService';
import Pagination from 'v-pagination-3';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { useToast } from 'vue-toastification';

export default {
  name: 'BusHospitalPage',
  components: {
    Pagination,
    InstitutionHospitalCreateModal,
    InstitutionHospitalModifyModal,
    InstitutionHospitalDeleteModal,
    LoadingSpinner,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    document.title = 'ONIONE | 기관-병원';

    return {
      isLoading: false,
      format,
      ko,
      parseISO,
      headerList: ['No.', '기관', '병원', '연결일', '수정', '삭제'],
      changeSearchType(e) {
        this.searchType = e.target.value;
      },

      institutionHospitalList: {},
      page: 1,
      totalPages: 1,
      currentElementCount: 1,
      searchType: 'institutionName',
      searchTypeSelect: [
        { name: '기관명', value: 'institutionName' },
        { name: '병원명', value: 'busHospitalName' },
      ],
      searchValue: '',
    };
  },

  mounted() {
    this.getLinkList();
  },

  methods: {
    resetFilter() {
      this.searchType = 'institutionName';
      this.searchValue = '';
      this.page = 1;
      this.getLinkList();
    },

    paginate() {
      this.getLinkList();
    },

    showCreateModal() {
      this.$vfm.show('InstitutionHospitalCreateModal');
    },

    showModifyModal(
      institutionId,
      institutionName,
      busHospitalId,
      busHospitalName
    ) {
      this.$vfm.show('InstitutionHospitalModifyModal', {
        institutionId: institutionId,
        institutionName: institutionName,
        busHospitalId: busHospitalId,
        busHospitalName: busHospitalName,
      });
    },
    showDeleteModal(institutionId) {
      this.$vfm.show('InstitutionHospitalDeleteModal', {
        institutionId: institutionId,
      });
    },

    async getLinkList() {
      if (!(this.searchValue.length < 31)) {
        this.toast.error('검색어는 30자 이하로 입력해주세요.', {
          bodyClassName: ['custom-class-1', 'custom-class-2'],
        });
        return;
      }

      this.isLoading = true;
      await InstitutionHospitalService.getLinkList(
        this.page,
        this.searchType,
        this.searchValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.institutionHospitalList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getLinkListError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
