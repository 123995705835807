import http from '/axiosconfig';

class BusHospitalService {
  getLinkList(page, type, searchValue) {
    return http.get(
      `/v1/institution/relation/_find?page=${page}&type=${encodeURIComponent(
        type
      )}&keyword=${encodeURIComponent(searchValue)}`
    );
  }

  getHospitalList(page, possibility, hospitalValue) {
    return http.get(
      `/v1/institution/relation/bus-hospital/_find?page=${page}&possibility=${encodeURIComponent(
        possibility
      )}&name=${encodeURIComponent(hospitalValue)}`
    );
  }

  geInstitutionList(page, possibility, hospitalValue) {
    return http.get(
      `/v1/institution/relation/institution/_find?page=${page}&possibility=${encodeURIComponent(
        possibility
      )}&name=${encodeURIComponent(hospitalValue)}`
    );
  }

  createLink(institutionId, busHospitalId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/relation/_create`;

    const data = {
      institutionId: institutionId,
      busHospitalId: busHospitalId,
    };

    return http.post(url, data);
  }

  updateLink(institutionId, busHospitalId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/relation/_update`;

    const data = {
      institutionId: institutionId,
      busHospitalId: busHospitalId,
    };

    return http.post(url, data);
  }

  deleteLink(institutionId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/relation/_delete`;

    const data = {
      institutionId: institutionId,
    };

    return http.post(url, data);
  }
}

export default new BusHospitalService();
