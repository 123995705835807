<template>
  <div>
    <!-- First modal -->
    <vue-final-modal
      v-model="showBusDetailModal"
      name="BusDetailModal"
      classes="modal-container"
      content-class="modal-content"
      @closed="closedBusDetailModal"
      @before-open="beforeOpenBusDetailModal"
      escToClose
    >
      <div class="h-full overflow-auto scrollbar-hide relative">
        <div
          class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0"
        >
          <p class="font-[600] text-[#ffffff] text-[17px]">버스 상세 정보</p>
          <button @click="onClose('showBusDetailModal')">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="w-[998px] bg-[#EEF0F4] p-[24px]">
          <div class="bg-[#ffffff] rounded-[12px] h-[250px] p-[32px] mb-[16px]">
            <table>
              <tbody>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    버스 번호
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.id ?? '' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    차량번호
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.vehicleNumber ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    상태
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{
                      bus.status === 'ACTIVE'
                        ? '정상'
                        : bus.status === 'DISABLED'
                        ? '종료'
                        : ''
                    }}
                  </td>
                </tr>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    운영병원
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.allocatedHospital ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    최근검진일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.currentExaminationDate ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    검진횟수
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.phone ?? '-' }}
                  </td>
                </tr>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    등록일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.createdDate ?? '-' }}
                  </td>

                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    운영종료일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ bus.closeDate ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="w-full flex justify-end">
              <button
                class="text-[#9A9EAC] underline font-[400] mt-[15px] text-[13px]"
                @click="showBusUpdateModal = true"
              >
                정보 수정
              </button>
            </div>
          </div>
          <div class="bg-[#ffffff] rounded-[12px] h-[450px] p-[32px]">
            <div class="mb-[24px]">
              <button
                @click="
                  page = 1;
                  tab = '검진 내역';
                  getBusExamination();
                "
                class="text-[15px] mr-[24px]"
                :class="[
                  tab === '검진 내역'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                검진 내역
              </button>
              <button
                @click="
                  page = 1;
                  tab = '병원 변경 내역';
                  getBusHospitalLink();
                "
                class="text-[15px]"
                :class="[
                  tab === '병원 변경 내역'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                병원 변경 내역
              </button>
            </div>
            <div
              :class="[
                historyList?.pages?.length === 0
                  ? 'w-full bg-white rounded-[12px] h-[280px]'
                  : ' bg-white rounded-[12px] h-[280px] overflow-auto scrollbar-hide',
              ]"
              ref="HistoryDiv"
            >
              <LoadingSpinner v-if="isLoading"></LoadingSpinner>
              <table v-else class="bg-[#FAFAFB] rounded-[12px] w-full">
                <thead v-if="tab === '검진 내역'" class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in [
                        'No.',
                        '운영 병원',
                        '검진 기관',
                        '검진 과목',
                        '검진 인원',
                        '검진일',
                      ]"
                      :key="'header-' + index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ column }}</p>
                        <div
                          class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0px] items-center justify-center"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <thead v-if="tab === '병원 변경 내역'" class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in [
                        'No.',
                        '병원',
                        '변경일',
                        '변경 ID',
                      ]"
                      :key="'header-' + index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ column }}</p>
                        <div
                          class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[-2px] items-center justify-center"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="tab === '검진 내역' && historyList?.pages?.length !== 0"
                >
                  <tr v-for="(item, index) in historyList.pages" :key="index">
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.no ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.allocatedHospital ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.allocatedInstitution ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.subject ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.patientCount ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.date }}
                        ({{
                          format(parseISO(item.date), 'eee', { locale: ko })
                        }}) {{ item.startTime }} ~ {{ item.endTime }}
                      </p>
                    </td>
                  </tr>
                </tbody>

                <tbody
                  v-if="
                    tab === '병원 변경 내역' && historyList?.pages?.length !== 0
                  "
                >
                  <tr v-for="(item, index) in historyList.pages" :key="index">
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.no ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.allocatedHospital ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.createdDate ?? '-' }}
                      </p>
                    </td>
                    <td
                      class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                    >
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                      >
                        {{ item.createdBy ?? '-' }}
                      </p>
                    </td>
                  </tr>
                </tbody>
                <div
                  v-if="historyList?.pages?.length === 0"
                  class="w-full h-full flex items-center justify-center select-none mx-auto"
                >
                  <div
                    class="absolute left-[40%] bottom-[5%] flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>조회되는 검색 내역이 없습니다.</p>
                  </div>
                </div>
              </table>
            </div>
            <div
              class="w-full pt-[24px] pb-[40px] flex justify-center"
              v-if="historyList && historyList?.pages?.length !== 0"
            >
              <pagination
                v-model="page"
                :records="
                  historyList.totalElementCount
                    ? Number(historyList?.totalElementCount)
                    : 1
                "
                :per-page="15"
                @paginate="paginate"
              />
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>

    <!-- Second modal -->
    <vue-final-modal
      v-model="showBusUpdateModal"
      classes="modal-container"
      content-class="modal-content"
      escToClose
    >
      <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
        <div
            class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0"
          >
          <p class="font-[600] text-[#ffffff] text-[17px]">버스 정보 수정</p>
                <button @click="onClose('showBusUpdateModal')">
                  <img src="@/assets/images/modal_close_icon.svg" />
                </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div
            class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
          >
            <div class="flex flex-col w-full space-y-[46px]">
              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  버스 번호
                </p>

                <div
                  class="bg-[#FAFAFB] border border-[#DDDFE3] select-none w-full items-center px-[16px] py-[11.5px] rounded-[8px] text-[14px]"
                >
                  <p class="text-[#7E8295] font-[400]">{{ bus.id ?? '-' }}</p>
                </div>
              </div>

              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  차량 번호
                </p>

                <div
                  class="bg-[#FAFAFB] border border-[#DDDFE3] select-none w-full items-center px-[16px] py-[11.5px] rounded-[8px] text-[14px]"
                >
                  <p class="text-[#7E8295] font-[400]">
                    {{ bus.vehicleNumber ?? '-' }}
                  </p>
                </div>
              </div>

              <div class="flex space-x-[24px] items-center">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
                >
                  상태
                </p>

                <select
                  v-if="bus.allocatedHospital === null"
                  v-model="selected.status"
                  class="bg-white border border-[#DDDFE3] select-none w-full items-center px-[16px] py-[11.5px] rounded-[8px] text-[14px]"
                >
                  <option
                    v-for="(item, index) in selectList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>

                <div
                  v-else
                  class="bg-[#FAFAFB] border border-[#DDDFE3] select-none w-full items-center text-[14px] text-[#7E8295] px-[16px] py-[11.5px] rounded-[8px]"
                >
                  {{
                    bus.status === 'ACTIVE'
                      ? '정상'
                      : bus.status === 'DISABLED'
                      ? '종료'
                      : ''
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="showBusUpdateModal = false"
              >
                취소
              </button>
                <button v-if="!isLoading"
                  class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                  @click="updateBusInfo"
                >
                  수정
                </button>

                 <button v-else
                    class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                
                  >
                    수정
                  </button>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import Pagination from 'v-pagination-3';
import BusService from '@/services/BusService';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useToast } from 'vue-toastification';

export default {
  name: 'BusDetail',
  components: {
    Pagination,
  },

  computed: {
    selected: function () {
      return { status: this.bus.status };
    },
  },

  props: {
    getBusList: Function,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    isLoading: false,
    busId: '',
    showBusDetailModal: false,
    showBusUpdateModal: false,
    page: 1,
    selectList: [
      { name: '정상', value: 'ACTIVE' },
      { name: '종료', value: 'DISABLED' },
    ],
    historyList: {},
    bus: {},
    tab: '검진 내역',
    format,
    ko,
    parseISO,
  }),

  methods: {
    onClose(modal) {
      this[modal] = false;
    },
    closedBusDetailModal() {
      this.busId = '';
      this.tab = '검진 내역';
      this.page = 1;
      this.historyList = {};
      this.bus = {};
      this.getBusList();
    },

    beforeOpenBusDetailModal() {
      this.busId =
        this?.$vfm?.get('BusDetailModal')?.[0]?.params?._value?.busId;
      this.getBusExamination();
      this.getBusInfo();
    },

    paginate() {
      if (this.tab === '검진 내역') {
        this.getBusExamination();
      }
      if (this.tab === '병원 변경 내역') {
        this.getBusHospitalLink();
      }
    },

    confirm() {
      this.showBusUpdateModal = false;
      this.showBusDetailModal = false;
      this.selectValue = '';
    },

    async getBusHospitalLink() {
      this.isLoading = true;

      await BusService.getBusHospitalLink(this.busId, this.page)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.historyList = result.data.data;
            this.$refs.HistoryDiv.scrollTop = 0;
          }
        })
        .catch((error) => console.error('getBusHospitalLinkError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getBusExamination() {
      this.isLoading = true;
      await BusService.getBusExamination(this.busId, this.page)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.historyList = result.data.data;
            this.$refs.HistoryDiv.scrollTop = 0;
          }
        })
        .catch((error) => console.error('getBusExaminationError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getBusInfo() {
      this.isLoading = true;
      await BusService.getBusInfo(this.busId) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.bus = result.data.data;
            this.selectValue = result.data.data.status;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateBusInfo() {
      this.isLoading = true;
      await BusService.updateBusInfo(
        this.busId,
        this.bus.vehicleNumber,
        this.selected.status
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('버스 정보가 수정되었습니다.');
            this.showBusUpdateModal = false;
            this.getBusInfo();
          } else if (result?.data?.resultCode === 7007) {
            this.toast.error(
              `연결된 병원 정보가 있어 상태 변경이 불가합니다.\n병원 정보 삭제 후 다시 시도해주세요.`
            );
          } else if (
            result?.data?.resultCode !== 200 &&
            result?.data?.resultCode !== 7007
          ) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          setTimeout(() => { this.isLoading = false; }, 1000);
        });
    },
  },
};
</script>

<style></style>
