<template>
  <CheckupCreateModal
    :getCheckupList="getCheckupList"
    v-if="showCheckupCreateModal"
  />
  <CheckupDetailModal
    v-if="checkupDetail"
    :checkupDetail="checkupDetail"
    :getCheckupList="getCheckupList"
  />
  <div class="flex justify-between pb-[38px]">
    <h1 class="text-[30px] font-[700] text-left">검진 내역</h1>

    <button
      @click="showCheckupCreateModal"
      class="bg-[#AB3B94] hover:bg-[#b34e9e] flex items-center justify-center space-x-[12px] cursor-pointer px-[20px] rounded-[8px]"
    >
      <img
        class="w-[18px] h-[18px] relative"
        src="@/assets/images/common_plus.svg"
      />
      <p
        class="text-white text-[14px] font-[600] flex items-center justify-center"
      >
        예약 등록
      </p>
    </button>
  </div>

  <div
    class="bg-white rounded-[12px] mb-[16px] flex px-[32px] py-[38px] space-x-[10px]"
  >
    <div class="flex flex-col min-w-[124px] space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">상태</p>

      <select
        v-model="status"
        :value="status"
        class="bg-white border border-onione-N200 select-none w-[124px] items-center px-[16px] py-[11.5px] rounded-[8px] text-[14px] text-onione-N600 font-[500]"
      >
        <option
          v-for="(item, index) in statusSelect"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">기간 선택</p>
      <div class="flex">
        <span
          class="text-[#65697B] text-[14px] font-[500] select-none whitespace-nowrap pr-[40px] pl-[16px] flex items-center justify-center border-[1px] border-onione-N200 border-r-0 rounded-tl-[8px] rounded-bl-[8px]"
          >검진일</span
        >

        <VDatePicker v-model="range" is-range>
          <template #default="{ inputValue, inputEvents }">
            <div
              class="border-[1px] h-[46px] px-[17px] flex justify-center items-center rounded-r-[8px] text-onione-N900 font-[400]"
            >
              <div v-on="inputEvents.start" class="flex items-center">
                <input class="w-[100px]" :value="inputValue.start" />
                <img
                  class="w-[18px] h-[18px] relative"
                  src="@/assets/images/calendar_icon.svg"
                />
              </div>

              <span class="px-[18px]">-</span>

              <div v-on="inputEvents.end" class="flex items-center">
                <input class="w-[100px]" :value="inputValue.end" />
                <img
                  class="w-[18px] h-[18px] relative"
                  src="@/assets/images/calendar_icon.svg"
                />
              </div>
            </div>
          </template>
        </VDatePicker>
      </div>
    </div>

    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">검색</p>

      <div class="flex">
        <select
          v-model="searchType"
          class="min-w-[124px] bg-white border-y-[1px] border-l-[1px] border-onione-N200 select-none items-center px-[16px] rounded-[8px] rounded-r-none text-onione-N600 font-[500] text-[14px]"
        >
          <option
            v-for="(item, index) in searchTypeSelect"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>

        <input
          className="h-[46px] w-[15.6vw] border-[1px] border-onione-N200 rounded-tr-[8px]  rounded-br-[8px] px-[20px] text-onione-N900  placeholder:text-[14px] placeholder:text-onione-N300 text-[14px]"
          id="searchValue"
          type="text"
          v-model="searchValue"
          autocomplete="off"
          placeholder="검색어"
          @keyup.enter="getCheckupList"
        />

        <button
          @click="getCheckupList"
          class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
        >
          <p
            class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            검색
          </p>
        </button>

        <button
          @click="resetFilter"
          class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-onione-N200 w-[88px]"
        >
          <p
            class="text-[#65697B] text-[14px] font-[400] flex items-center justify-center whitespace-nowrap"
          >
            초기화
          </p>
        </button>
      </div>
    </div>
  </div>

  <div class="w-full bg-white rounded-[12px]">
    <LoadingSpinner v-if="isLoading"></LoadingSpinner>
    <table v-else class="bg-[#FAFAFB] rounded-[12px] w-full">
      <thead class="bg-[#EBECEF]">
        <tr class="rounded-t-[12px]">
          <th
            class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
            v-for="(column, index) in headerList"
            :key="index"
          >
            <div
              class="absolute right-[0px] top-[calc(50%-8px)] h-[16px] bg-onione-N200"
              :class="[index === headerList.length - 1 ? 'w-[0px]' : 'w-[1px]']"
            />

            <div class="flex items-center justify-center w-full">
              <p class="font-[600]">{{ headerList[index] }}</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="checkupList?.pages?.length !== 0">
        <tr
          v-for="(checkupItem, index) in checkupList.pages"
          :key="index"
          class="h-[56px] border-b-[#EBECEF] border-[1px]"
        >
          <td class="px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ checkupItem.no ?? '-' }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{
                checkupItem.status === 'REQUEST'
                  ? '예약 신청'
                  : checkupItem.status === 'CONFIRM'
                  ? '예약 확정'
                  : checkupItem.status === 'COMPLETE'
                  ? '검진 완료'
                  : checkupItem.status === 'CANCEL'
                  ? '예약 취소'
                  : ''
              }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ checkupItem.institutionData.name ?? '-' }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ checkupItem.hospitalData.name ?? '-' }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ checkupItem.subject ?? '-' }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ checkupItem.count ?? '-' }}
            </p>
          </td>
          <td class="py-[20px] px-[20px]">
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{
                format(parseISO(checkupItem?.date), 'yyyy. MM. dd (eee)', {
                  locale: ko,
                }) ?? '-'
              }}
              {{ checkupItem.startTime ?? '-' }} ~
              {{ checkupItem.endTime ?? '-' }}
            </p>
          </td>
          <td class="flex justify-center py-[20px] px-[20px]">
            <button
              @click="showCheckupDetailModal(checkupItem.id)"
              class="flex justify-center items-center w-[60px] text-onione-N600 text-[14px] font-[400] border-[1px] border-onione-N200 rounded-[8px] py-[4px] cursor-pointer whitespace-nowrap"
            >
              보기
            </button>
          </td>
        </tr>
      </tbody>
      <div
        v-else
        class="w-full h-[70vh] flex items-center justify-center select-none"
      >
        <div
          class="absolute left-[50%] flex flex-col items-center space-y-[24px]"
        >
          <img
            class="w-[48px] h-[48px] relative"
            src="@/assets/images/common_notice.svg"
          />
          <p>일치하는 검색 결과가 없습니다.</p>
        </div>
      </div>
    </table>
    <div class="w-full pt-[24px] pb-[40px] flex justify-center">
      <pagination
        v-model="page"
        :records="
          checkupList.totalElementCount
            ? Number(checkupList?.totalElementCount)
            : 1
        "
        :per-page="15"
        @paginate="getCheckupList"
      />
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';

import CheckupService from '@/services/CheckupService';
import CheckupCreateModal from '@/components/modal/CheckupCreateModal';
import CheckupDetailModal from '@/components/modal/CheckupDetailModal';
import Pagination from 'v-pagination-3';
import { format, parseISO, getYear, getMonth, endOfMonth } from 'date-fns';
import { ko } from 'date-fns/locale';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { useToast } from 'vue-toastification';

export default {
  name: 'CheckupPage',
  components: {
    CheckupCreateModal,
    CheckupDetailModal,
    LoadingSpinner,
    Pagination,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    document.title = 'ONIONE | 검진내역';

    return {
      checkupDetail: {},
      isLoading: false,
      format,
      ko,
      parseISO,
      status: 'n99',
      statusSelect: [
        { name: '전체', value: 'n99' },
        { name: '예약 확정', value: 'CONFIRM' },
        { name: '검진 완료', value: 'COMPLETE' },
        { name: '예약 취소', value: 'CANCEL' },
      ],
      range: ref({
        start: new Date(getYear(new Date()), getMonth(new Date()), 1),
        end: endOfMonth(new Date()),
      }),
      searchType: 'institution',
      searchTypeSelect: [
        { name: '기관명', value: 'institution' },
        { name: '병원명', value: 'hospital' },
      ],
      searchValue: '',
      headerList: [
        'No.',
        '상태',
        '기관',
        '검진 병원',
        '검진 과목',
        '검진 인원',
        '검진일',
        '상세',
      ],
      checkupList: {},
      page: 1,
      totalPages: 1,
      currentElementCount: 1,
    };
  },

  mounted() {
    this.getCheckupList();
  },
  methods: {
    changeSearchType(e) {
      this.searchType = e.target.value;
    },

    showCheckupCreateModal() {
      this.$vfm.show('showCheckupCreateModal');
    },
    showCheckupDetailModal(reservationId) {
      this.getCheckupDeatil(reservationId).then(() => {
        this.$vfm.show('showCheckupDetailModal');
      });
    },

    showCreateModal() {
      this.$vfm.show('HospitalCreateModal');
    },
    resetFilter() {
      this.page = 1;
      this.status = 'n99';
      this.searchType = 'institution';
      this.searchValue = '';
      (this.range = ref({
        start: new Date(getYear(new Date()), getMonth(new Date()), 1),
        end: new Date(),
      })),
        this.getCheckupList();
    },
    async getCheckupList() {
      if (!(this.searchValue.length < 31)) {
        this.toast.error('검색어는 30자 이하로 입력해주세요.', {
          bodyClassName: ['custom-class-1', 'custom-class-2'],
        });
        return;
      }

      this.isLoading = true;
      await CheckupService.getCheckupList(
        this.page,
        this.status,
        this.searchType,
        this.searchValue,
        format(this.range.start, 'yyyy-MM-dd'),
        format(this.range.end, 'yyyy-MM-dd')
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.checkupList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getCheckupDeatil(reservationId) {
      await CheckupService.getCheckupDeatil(reservationId)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.checkupDetail = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getReservationDeatilError', error))
        .finally(() => {});
    },
  },
};
</script>
<style></style>
