import { computed, reactive } from 'vue';
import http from '/axiosconfig';

const User = reactive({
  username: '',
  loggedIn: false,
});

const getters = reactive({
  isLoggedIn: computed(() => User.loggedIn),
});

const storeActions = {
  async getAuthStatusServer() {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      try {
        const result = await http.get(`/v1/member/local-branch/_me`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        User.loggedIn = result.data.resultCode === 200 ? true : false;
        User.username = result.data.data.username;
        return Boolean(User.loggedIn);
      } catch (error) {
        console.error(error.message);
        return false;
      }
    }
  },
};

export default {
  state: User,
  getters,
  ...storeActions,
};
