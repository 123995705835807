<template>
  <div
    className="bg-[#03002E] min-h-screen w-screen flex justify-center items-center bg-login bg-no-repeat bg-cover bg-center py-[100px]"
  >
    <div
      className="text-[rgb(49,58,69)] bg-[#ffffff] w-[544px] h-[674px] rounded-[12px] pt-[96px] relative bg-center"
    >
      <div className="mb-[48px]  px-[108px]">
        <h2 className="font-[700] text-[28px] mb-[8px]">로그인</h2>
      </div>
      <div>
        <form className="px-[108px]">
          <div className="">
            <label className="flex flex-col text-[14px] mb-[8px]" htmlFor="id">
              아이디
            </label>
            <input
              className="w-[328px] h-[50px] border-[1px] rounded-[12px] px-[20px]"
              id="userId"
              type="text"
              required
              v-model="userId"
            />
          </div>
          <div className="mt-[20px]">
            <label
              className="flex flex-col text-[14px] mb-[8px]"
              htmlFor="password"
            >
              비밀번호
            </label>
            <input
              className="w-[328px] h-[50px] border-[1px] rounded-[12px] px-[20px]"
              id="password"
              name="password"
              type="password"
              required
              autoComplete="current-password"
              v-model="password"
            />
          </div>
          <div className="mt-[42px]">
                      <button v-if="!isLoading"
                      @click="handleLogin"
                      className="bg-slate-500 w-[100%] h-[56px] rounded-[8px] text-[13px] text-[#ffffff]"
                    >
                      로그인
                    </button>

                      <button v-else
                        type="button"
                        className="bg-slate-500 w-[100%] h-[56px] rounded-[8px] text-[13px] text-[#ffffff]"
                      >
                        로그인
                      </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import store from '@/store';
import { useToast } from 'vue-toastification';

export default {
  name: 'LoginPage',
  beforeEnter: () => {},

  // 데이터 보관장소
  data() {
    document.title = 'ONIONE | 로그인';
    return {
      userId: '',
      password: '',
      isLoading: false,
    };
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    async handleLogin(e) {
      e.preventDefault();

      if (this.userId?.trim().length === 0) {
        this.toast.error('아이디를 입력해 주세요.');
        return;
      }

      if (this.password?.trim().length === 0) {
        this.toast.error('비밀번호를 입력해 주세요.');
        return;
      }


      this.isLoading = true;
      await AuthService.loginUser(this.userId, this.password) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            sessionStorage.setItem('accessToken', result.data.data.accessToken)
            sessionStorage.setItem('refreshToken', result.data.data.refreshToken)
            this.$router.replace('/');
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('loginError', error)) //
        .finally(() => { setTimeout(() => { this.isLoading = false; }, 1000) });
      await store.getAuthStatusServer();
    },
  },
};
</script>

<style></style>
