<template>
  <HospitalUpdateModal
    :getHospitalInfo="getHospitalInfo"
    :getHospitalSchedule="getHospitalSchedule"
  />

  <HospitalScheduleModal
    :getHospitalSchedule="getHospitalSchedule"
    :scheduleInfo="scheduleInfo"
  />

  <vue-final-modal
    @before-open="beforeOpenHospitalDetailModal"
    @closed="closedHospitalDetailModal"
    v-model="showHospitalDetailModal"
    name="hospitalDetailModal"
    classes="modal-container"
    content-class="modal-content"
    escToClose
  >
    <div class="h-full overflow-auto scrollbar-hide relative">
      <div
        class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">병원 상세 정보</p>

        <button @click="onClose('showHospitalDetailModal')">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="w-[998px] bg-[#EEF0F4] p-[24px]">
        <div class="bg-[#ffffff] rounded-[12px] h-[301px] p-[32px] mb-[16px]">
          <table>
            <tbody>
              <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  병원 번호
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.id ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  병원명
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.name ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  상태
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{
                    hospitalInfo.status === 'ACTIVE'
                      ? '정상'
                      : hospitalInfo.status === 'DISABLED'
                      ? '종료'
                      : ''
                  }}
                </td>
              </tr>
              <tr class="h-[50px] border-y-[1px] border-y-[##EBECEF]">
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  검진버스
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.allocatedBusNumber ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  주소
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.streetAddress ?? '-' }}
                  <br />
                  {{ hospitalInfo.detailAddress ?? '' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  연락처
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.phone ?? '-' }}
                </td>
              </tr>
              <tr class="h-[50px] border-y-[1px] border-y-[##EBECEF]">
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  담당자 연락처
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.managerPhone ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  최근 검진일
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.currentExaminationDate ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  검진 횟수
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.examinationCount ?? '-' }}
                </td>
              </tr>
              <tr class="h-[50px] border-y-[1px] border-y-[##EBECEF]">
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  등록일
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.createdDate ?? '-' }}
                </td>
                <td
                  class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                >
                  운영 종료일
                </td>
                <td
                  class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                >
                  {{ hospitalInfo.closeDate ?? '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="w-full flex justify-end">
            <button
              class="text-[#9A9EAC] underline font-[400] mt-[15px] text-[13px]"
              @click="onShowHospitalUpdateModal()"
            >
              정보 수정
            </button>
          </div>
        </div>
        <div class="bg-[#ffffff] rounded-[12px] h-[450px] p-[32px]">
          <div class="mb-[24px]">
            <button
              @click="onClickCheckScedule"
              class="text-[15px] mr-[24px]"
              :class="[
                tab === '검진 스케쥴'
                  ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                  : 'font-[500] text-onione-N400',
              ]"
            >
              검진 스케쥴
            </button>
            <button
              @click="
                tab = '검진 내역';
                getExamination();
                this.page = 1;
              "
              class="text-[15px]"
              :class="[
                tab === '검진 내역'
                  ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                  : 'font-[500] text-onione-N400',
              ]"
            >
              검진 내역
            </button>
          </div>
          <div
            v-if="tab === '검진 스케쥴'"
            class="schedule_calendar w-full bg-white h-[360px] overflow-y-scroll scrollbar-hide"
            ref="HistoryDiv"
          >
            <VCalendar
              v-if="showHospitalDetailModal"
              expanded
              borderless
              :attributes="attributes"
              :masks="masks"
              @dayclick="onDayClickHandler"
              @did-move="onDidMoveHandler"
              :disabled-dates="disabledDates"
              :initial-page="{
                month: format(new Date(), 'MM'),
                year: format(new Date(), 'yyyy'),
              }"
            >
              <template #day-popover="{ dayTitle, attributes }">
                <div class="px-3 py-2">
                  <ul>
                    <li
                      v-for="{ key, customData } in attributes"
                      :key="key"
                      class="block text-gray-700 dark:text-gray-300 text-[14px]"
                    >
                      <div
                        class="text-gray-700 dark:text-gray-300 font-semibold text-center mb-1 text-[14px]"
                      >
                        {{ dayTitle }}
                      </div>
                      <div>
                        <p>
                          <span
                            class="inline-block w-[8px] h-[8px] rounded-[50%]"
                            :class="
                              customData.availableReservation === true
                                ? 'bg-onione-G400'
                                : 'bg-onione-R400'
                            "
                          />
                          {{ customData.startTime }}
                          <span v-if="customData.startTime">~</span>
                          {{ customData.endTime }} {{ customData.subject }}
                        </p>
                      </div>
                      <div
                        v-for="(reservation, index) in customData.reservations"
                        :key="index"
                      >
                        <div class="flex gap-[3px]">
                          <div
                            class="text-[#ffffff] text-[12px] rounded-[4px] max-w-[54px] h-[22px] px-[4px] flex justify-center items-center cursor-default"
                            :class="[
                              reservation.status === 'CONFIRM'
                                ? 'bg-onione-B400'
                                : reservation.status === 'COMPLETE'
                                ? 'bg-onione-G400'
                                : reservation.status === 'REQUEST'
                                ? 'bg-onione-N300'
                                : 'bg-onione-N300',
                            ]"
                          >
                            {{
                              reservation.status === 'REQUEST'
                                ? '예약신청'
                                : reservation.status === 'CONFIRM'
                                ? '예약확정'
                                : reservation.status === 'COMPLETE'
                                ? '검진완료'
                                : reservation.status === 'CANCEL'
                                ? '예약취소'
                                : ''
                            }}
                          </div>

                          {{ reservation.institutionName }}
                          ({{ reservation.patientCount }}명)
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </VCalendar>
            <div
              class="flex items-center gap-[8px] justify-end mt-[16px] text-[14px]"
            >
              <p class="mr-[16px]">
                <span
                  class="inline-block w-[8px] h-[8px] bg-onione-G400 rounded-[50%]"
                />

                스케쥴 등록
              </p>
              <p>
                <span
                  class="inline-block w-[8px] h-[8px] bg-onione-R400 rounded-[50%]"
                />

                검진예약
              </p>
              <p></p>
            </div>
          </div>

          <div
            v-if="tab === '검진 내역'"
            :class="[
              historyList?.pages?.length === 0
                ? 'w-full bg-white rounded-[12px] h-[280px]'
                : ' bg-white rounded-[12px] h-[280px] overflow-auto scrollbar-hide',
            ]"
          >
            <table class="bg-[#FAFAFB] rounded-[12px] w-full">
              <thead class="bg-[#EBECEF]">
                <tr class="rounded-t-[12px]">
                  <th
                    class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                    v-for="(column, index) in [
                      'No.',
                      '버스',
                      '검진 기관',
                      '검진 과목',
                      '검진 인원',
                      '검진일',
                    ]"
                    :key="'header-' + index"
                  >
                    <div class="flex items-center justify-center">
                      <p class="font-[600]">{{ column }}</p>
                      <div
                        class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center"
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody v-if="historyList?.pages?.length !== 0">
                <tr v-for="(item, index) in historyList.pages" :key="index">
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.no ?? '-' }}
                    </p>
                  </td>
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.vehicleNumber ?? '-' }}
                    </p>
                  </td>
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.allocatedInstitution ?? '-' }}
                    </p>
                  </td>
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.subject ?? '-' }}
                    </p>
                  </td>
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.patientCount ?? '-' }}
                    </p>
                  </td>
                  <td
                    class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
                  >
                    <p
                      class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
                    >
                      {{ item.date ?? '-' }}
                    </p>
                  </td>
                </tr>
              </tbody>
              <div
                v-if="historyList?.pages?.length === 0"
                class="w-full h-full flex items-center justify-center select-none mx-auto"
              >
                <div
                  class="absolute left-[40%] bottom-[5%] flex flex-col items-center space-y-[24px]"
                >
                  <img
                    class="w-[48px] h-[48px] relative"
                    src="@/assets/images/common_notice.svg"
                  />
                  <p>조회되는 검색 내역이 없습니다.</p>
                </div>
              </div>
            </table>
          </div>
          <div
            class="w-full pt-[24px] pb-[40px] flex justify-center"
            v-if="tab === '검진 내역' && historyList?.pages?.length !== 0"
          >
            <pagination
              v-model="page"
              :records="
                historyList.totalElementCount
                  ? Number(historyList?.totalElementCount)
                  : 1
              "
              :per-page="15"
              @paginate="paginate"
            />
          </div>

          <div
            class="w-full pt-[24px] pb-[40px] flex justify-center"
            v-if="scheduleList"
          ></div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Pagination from 'v-pagination-3';
import HospitalService from '@/services/HospitalService';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import HospitalUpdateModal from '@/components/modal/HospitalUpdateModal';
import HospitalScheduleModal from '@/components/modal/HospitalScheduleModal';

const disabledDates = ref([{ start: null, end: new Date() }]);

const masks = ref({
  title: 'YYYY.MM',
  dayPopover: 'YYYY.MM.DD',
});

export default {
  name: 'HospitalDetailModal',
  components: {
    Pagination,
    HospitalScheduleModal,
    HospitalUpdateModal,
  },

  props: {
    getHospitalList: Function,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    format,
    ko,
    parseISO,
    selectList: [
      { name: '정상', value: 'ACTIVE' },
      { name: '종료', value: 'DISABLED' },
    ],

    isLoading: false,
    calendarMonth: format(new Date(), 'yyyy-MM'),
    masks: masks,
    attributes: [],
    disabledDates: disabledDates,
    hospitalId: '',
    showHospitalDetailModal: null,
    page: 1,
    scheduleList: [],
    hospitalInfo: {
      id: '',
      name: '',
      status: '',
      allocatedBusId: '',
      allocatedBusNumber: '',
      postNumber: '',
      streetAddress: '',
      detailAddress: '',
      phone: '',
      managerPhone: '',
      currentExaminationDate: '',
      examinationCount: '',
      createdDate: '',
      closeDate: '',
    },
    tab: '검진 스케쥴',
    scheduleInfo: {
      kind: '',
      title: '',
      customData: {
        availableReservation: '',
        startTime: '',
        endTime: '',
      },
      dates: new Date(),
    },
    historyList: {},
  }),

  methods: {
    onShowHospitalUpdateModal() {
      this.$vfm.show('hospitalUpdateModal', {
        hospitalInfo: this.hospitalInfo,
      });
    },
    closedHospitalDetailModal() {
      this.page = 1;
      this.tab = '검진 스케쥴';
      this.calendarMonth = format(new Date(), 'yyyy-MM');
      this.getHospitalList();
    },

    beforeOpenHospitalDetailModal() {
      this.hospitalId = this?.$vfm?.get(
        'hospitalDetailModal'
      )?.[0]?.params?._value?.hospitalId;
      this.getHospitalSchedule();
      this.getHospitalInfo();
    },

    onCloseModal() {
      this.showHospitalDetailModal = false;
    },
    onClickCheckScedule() {
      this.tab = '검진 스케쥴';
      this.calendarMonth = format(new Date(), 'yyyy-MM');
      this.getHospitalSchedule();
    },

    onDidMoveHandler(calendarInfo) {
      this.calendarMonth = calendarInfo[0].id;
      this.getHospitalSchedule();
    },

    onDayClickHandler(calendarInfo) {
      if (calendarInfo.date < new Date()) {
        return;
      }
      // 스케쥴 있는 경우
      const isSchedule = this?.attributes?.find(
        (item) => format(item?.dates, 'yyyy-MM-dd') == calendarInfo.id
      );

      // 등록
      if (isSchedule === undefined) {
        this.scheduleInfo = {
          kind: 'create',
          title: '검진 스케쥴 등록',

          customData: {
            availableReservation: null,
            subject: '',
            startTime: '',
            endTime: '',
            scheduleId: '',
            reservations: '',
          },
          dates: calendarInfo?.date,
        };
      } else if (isSchedule !== undefined) {
        this.scheduleInfo = {
          kind: 'update',
          title: '검진 스케쥴 수정',
          customData: isSchedule.customData,
          dates: isSchedule?.dates,
          startTime: isSchedule?.startTime,
          endTime: isSchedule?.endTime,
        };
      }

      if (calendarInfo.date > new Date()) {
        this.$vfm.show('hospitalScheduleModal', {
          hospitalId: this.hospitalId,
        });
      }
    },

    async scheduleDot() {
      if (this?.scheduleList.length > 0) {
        this.scheduleList.map((item) => {
          const greenItem = {
            dates: parseISO(item.availableDate),
            dot: {
              color: 'green',
            },
            popover: {
              visibility: 'hover',
            },
            customData: {
              availableReservation: item.availableReservation,
              subject: item.subject,
              startTime: item.startTime,
              endTime: item.endTime,
              scheduleId: item.scheduleId,
              reservations: item.reservations,
            },
          };
          const redItem = {
            dates: parseISO(item.availableDate),
            dot: {
              color: 'red',
            },
            popover: {
              visibility: 'hover',
            },
            scheduleItem: item,
            customData: {
              availableReservation: item.availableReservation,
              subject: item.subject,
              startTime: item.startTime,
              endTime: item.endTime,
              scheduleId: item.scheduleId,
              reservations: item.reservations,
            },
          };

          if (item.availableReservation === true) {
            this.attributes.push(greenItem);
          } else {
            this.attributes.push(redItem);
          }
        });
      }
    },
    paginate() {
      if (this.tab === '검진 내역') this.getExamination();
    },

    async getHospitalSchedule() {
      this.isLoading = true;
      this.attributes = [];
      await HospitalService.getHospitalSchedule(
        this.hospitalId,
        this.calendarMonth
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.scheduleList = result.data.data;
            this.scheduleDot();
            // this.$refs.HistoryDiv.scrollTop = 0;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalScheduleError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getExamination() {
      this.isLoading = true;
      await HospitalService.getExamination(this.hospitalId, this.page)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.historyList = result.data.data;
            // this.$refs.HistoryDiv.scrollTop = 0;
          }
        })
        .catch((error) => console.error('getExaminationError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getHospitalInfo() {
      this.isLoading = true;

      await HospitalService.getHospitalInfo(this.hospitalId) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.hospitalInfo = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
