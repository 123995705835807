import http from "/axiosconfig";

class BusHospitalService {
  getLinkList(page, status, searchValue) {
    return http.get(
      `/v1/bus-hospital/relation/_find?page=${page}&connectionInstitution=${encodeURIComponent(
        status
      )}&type=busHospitalName&keyword=${encodeURIComponent(searchValue)}`
    );
  }

  getHospitalList(page, possibility, hospitalValue) {
    return http.get(
      `/v1/bus-hospital/relation/bus-hospital/_find?page=${page}&possibility=${encodeURIComponent(
        possibility
      )}&name=${encodeURIComponent(hospitalValue)}`
    );
  }

  getBusList(page, possibility, hospitalValue) {
    return http.get(
      `/v1/bus-hospital/relation/bus/_find?page=${page}&possibility=${encodeURIComponent(
        possibility
      )}&vehicleNumber=${encodeURIComponent(hospitalValue)}`
    );
  }

  createLink(busId, busHospitalId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/relation/_create`;

    const data = {
      busId: busId,
      busHospitalId: busHospitalId,
    };

    return http.post(url, data);
  }

  updateLink(busId, busHospitalId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/relation/_update`;

    const data = {
      busId: busId,
      busHospitalId: busHospitalId,
    };

    return http.post(url, data);
  }

  deleteLink(busId, busHospitalId) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/bus-hospital/relation/_delete`;

    const data = {
      busId: busId,
      busHospitalId: busHospitalId,
    };

    return http.post(url, data);
  }
}

export default new BusHospitalService();
