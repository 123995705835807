<template>
  <ReservationModal v-if="showReservationModal" @update="getReservationList" />
  <div class="flex justify-between pb-[38px]">
    <h1 class="text-[30px] font-[700] text-left ]">예약 신청</h1>
  </div>

  <div
    class="h-20% bg-white rounded-[12px] mb-[16px] flex px-[32px] py-[38px] space-x-[10px]"
  >
    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">기간 선택</p>
      <div class="flex">
        <select
          v-model="dateType"
          :value="dateType"
          @change="changeTerm"
          class="bg-white border border-onione-N200 border-r-0 select-none w-[124px] items-center px-[16px] py-[11.5px] rounded-l-[8px] text-[14px] text-onione-N600 font-[500]"
        >
          <option
            v-for="(item, index) in dateTypeSelect"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>

        <VDatePicker v-model="range" is-range>
          <template #default="{ inputValue, inputEvents }">
            <div
              class="border-[1px] h-[46px] px-[17px] flex justify-center items-center rounded-r-[8px] text-onione-N900 font-[400]"
            >
              <div v-on="inputEvents.start" class="flex items-center">
                <input class="w-[100px]" :value="inputValue.start" />
                <img
                  class="w-[18px] h-[18px] relative"
                  src="@/assets/images/calendar_icon.svg"
                />
              </div>

              <span class="px-[18px]">-</span>

              <div v-on="inputEvents.end" class="flex items-center">
                <input class="w-[100px]" :value="inputValue.end" />
                <img
                  class="w-[18px] h-[18px] relative"
                  src="@/assets/images/calendar_icon.svg"
                />
              </div>
            </div>
          </template>
        </VDatePicker>
      </div>
    </div>

    <div class="flex flex-col space-y-[8px]">
      <p class="text-[14px] text-[#4E515F] font-[500]">검색</p>

      <div class="flex">
        <select
          v-model="searchType"
          @change="changeSearchType"
          class="min-w-[124px] bg-white border-y-[1px] border-l-[1px] border-onione-N200 select-none items-center px-[16px] rounded-[8px] rounded-r-none text-onione-N600 font-[500] text-[14px]"
        >
          <option
            v-for="(item, index) in searchTypeSelect"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>

        <input
          className="h-[46px] w-[15.6vw] border-[1px] border-onione-N200 rounded-tr-[8px]  rounded-br-[8px] px-[20px] text-onione-N900  placeholder:text-[14px] placeholder:text-onione-N300 text-[14px]"
          id="searchValue"
          type="text"
          v-model="searchValue"
          autocomplete="off"
          placeholder="검색어"
          @keyup.enter="getReservationList"
        />

        <button
          @click="getReservationList"
          class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]"
        >
          <p
            class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
          >
            검색
          </p>
        </button>

        <button
          @click="resetFilter"
          class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-onione-N200 w-[88px]"
        >
          <p
            class="text-[#65697B] text-[14px] font-[400] flex items-center justify-center whitespace-nowrap"
          >
            초기화
          </p>
        </button>
      </div>
    </div>
  </div>

  <div class="w-full bg-white rounded-[12px]">
    <LoadingSpinner v-if="isLoading"></LoadingSpinner>
    <table v-else class="bg-[#FAFAFB] rounded-[12px] w-full">
      <thead class="bg-[#EBECEF]">
        <tr class="rounded-t-[12px]">
          <th
            class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
            v-for="(column, index) in headerList"
            :key="index"
          >
            <div class="flex items-center justify-center">
              <p class="font-[600]">{{ headerList[index] }}</p>
              <div
                class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[-2px] items-center justify-center"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="reservationList?.pages?.length !== 0">
        <tr v-for="(item, index) in reservationList.pages" :key="index">
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ item.no ?? '-' }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ item.institutionData.name ?? '-' }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ item.hospitalData.name ?? '-' }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{ item.count ?? '-' }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{
                format(parseISO(item?.date), 'yyyy. MM. dd (eee)', {
                  locale: ko,
                }) ?? '-'
              }}
              {{ item?.startTime }} ~
              {{ item?.endTime }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <p
              class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center"
            >
              {{
                format(
                  parseISO(item?.requestDate),
                  'yyyy. MM. dd (eee) HH:mm:ss',
                  {
                    locale: ko,
                  }
                ) ?? '-'
              }}
            </p>
          </td>
          <td
            class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]"
          >
            <div class="flex space-x-[10px] w-full justify-center items-center">
              <p
                @click="showReservationModal('승인', item.id)"
                class="text-[#537EFF] text-[14px] px-[17px] font-[500] bg-[#F0F4FF] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer whitespace-nowrap"
              >
                승인
              </p>

              <p
                @click="showReservationModal('반려', item.id)"
                class="text-[#FF4869] text-[14px] px-[17px] font-[500] bg-[#FFF0F2] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer whitespace-nowrap"
              >
                반려
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <div
        v-else
        class="w-full h-[70vh] flex items-center justify-center select-none"
      >
        <div
          class="absolute left-[50%] flex flex-col items-center space-y-[24px]"
        >
          <img
            class="w-[48px] h-[48px] relative"
            src="@/assets/images/common_notice.svg"
          />
          <p>일치하는 검색 결과가 없습니다.</p>
        </div>
      </div>
    </table>
    <div class="w-full pt-[24px] pb-[40px] flex justify-center">
      <pagination
        v-model="page"
        :records="
          reservationList.totalElementCount
            ? Number(reservationList?.totalElementCount)
            : 1
        "
        :per-page="15"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import ReservationService from '@/services/ReservationService';
import ReservationModal from '@/components/modal/ReservationModal';
import Pagination from 'v-pagination-3';
import { format, parseISO, getYear, getMonth, endOfMonth } from 'date-fns';
import { ko } from 'date-fns/locale';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { useToast } from 'vue-toastification';

export default {
  name: 'HospitalPage',
  components: {
    LoadingSpinner,
    Pagination,
    ReservationModal,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    document.title = 'ONIONE | 예약신청';

    return {
      range: ref({
        start: new Date(getYear(new Date()), getMonth(new Date()), 1),
        end: endOfMonth(new Date()),
      }),
      isLoading: false,
      format,
      ko,
      parseISO,
      dateType: 'requestDate',
      dateTypeSelect: [
        { name: '예약 신청일', value: 'requestDate' },
        { name: '검진 희망일', value: 'reservationDate' },
      ],
      searchType: 'institution',
      searchTypeSelect: [
        { name: '기관명', value: 'institution' },
        { name: '병원명', value: 'hospital' },
      ],
      searchValue: '',
      headerList: [
        'No.',
        '기관',
        '검진 병원',
        '검진 인원',
        '검진 희망일',
        '예약 신청일',
        '승인 여부',
      ],
      reservationList: {},
      page: 1,
      totalPages: 1,
      currentElementCount: 1,

      showmodal: false,
      modalType: '',
    };
  },
  created() {},

  mounted() {
    this.getReservationList();
  },

  updated() {},

  methods: {
    changeTerm(e) {
      this.dateType = e.target.value;
    },
    changeSearchType(e) {
      this.searchType = e.target.value;
    },
    showReservationModal(modalType, reservationId) {
      this.$vfm.show('ReservationModal', {
        modalType: modalType,
        reservationId: reservationId,
      });
    },

    paginate() {
      this.getReservationList();
    },

    resetFilter() {
      this.page = 1;
      this.dateType = 'requestDate';
      this.searchType = 'institution';
      this.searchValue = '';
      (this.range = ref({
        start: new Date(getYear(new Date()), getMonth(new Date()), 1),
        end: new Date(),
      })),
        this.getReservationList();
    },

    async getReservationList() {
      if (!(this.searchValue.length < 31)) {
        this.toast.error('검색어는 30자 이하로 입력해주세요.', {
          bodyClassName: ['custom-class-1', 'custom-class-2'],
        });
        return;
      }

      this.isLoading = true;
      await ReservationService.getReservationList(
        this.page,
        this.dateType,
        format(this.range.start, 'yyyy-MM-dd'),
        format(this.range.end, 'yyyy-MM-dd'),
        this.searchType,
        this.searchValue
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.reservationList = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getReservationListError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style></style>
