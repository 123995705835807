export default {
  validateStartTimeAndEndTime(startTime, endTime) {
    //시간의 순서를 검사합니다.

    // 현재 날짜를 가져온 후, 시간 문자열을 결합합니다.
    const currentDate = new Date().toISOString().substring(0, 10);

    // 날짜 문자열을 생성합니다.
    const dateStr1 = `${currentDate}T${startTime}:00`;
    const dateStr2 = `${currentDate}T${endTime}:00`;

    // 날짜 문자열을 Date 객체로 변환
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // 두 날짜를 비교하여 시간 순서를 확인
    if (date1 < date2) {
      return true;
    }

    return false;
  },
};
