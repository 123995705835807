import http from '/axiosconfig';
// import router from '@/router';
class CheckupService {
  getCheckupList(page, status, searchType, searchValue, startDate, endDate) {
    if (status === 'n99') {
      return http.get(
        `/v1/institution/reservation/checkup/manager/_find?page=${page}&status=&searchType=${encodeURIComponent(
          searchType
        )}&searchValue=${encodeURIComponent(
          searchValue
        )}&startDate=${encodeURIComponent(
          startDate
        )}&endDate=${encodeURIComponent(endDate)}`
      );
    } else {
      return http.get(
        `/v1/institution/reservation/checkup/manager/_find?page=${page}&status=${encodeURIComponent(
          status
        )}&searchType=${encodeURIComponent(
          searchType
        )}&searchValue=${encodeURIComponent(
          searchValue
        )}&startDate=${encodeURIComponent(
          startDate
        )}&endDate=${encodeURIComponent(endDate)}`
      );
    }
  }

  getReservation(yearMonth, hospitalId, institutionId) {
    return http.get(
      `/v1/institution/reservation/_find?yearMonth=${yearMonth}&hospitalId=${encodeURIComponent(
        hospitalId
      )}&institutionId=${encodeURIComponent(institutionId)}`
    );
  }
  getCheckupDeatil(reservationId) {
    return http.get(
      `/v1/institution/reservation/${reservationId}/manager/_findone`
    );
  }

  createReservation(institutionId, scheduleId, count) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/reservation/manager/_create`;
    const data = {
      institutionId: institutionId,
      scheduleId: scheduleId,
      count: count,
    };

    return http.post(url, data);
  }

  updateStatus(reservationId, status) {
    const url = `${process.env.VUE_APP_BACKEND_ROOT_URL}/v1/institution/reservation/status/_update`;
    const data = {
      reservationId: reservationId,
      status: status,
    };

    return http.post(url, data);
  }
}

export default new CheckupService();
