<template>
  <div
    class="absolute z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-6 h-[full] w-[full] bg-white rounded-xl">
    <div class="flex flex-col justify-center items-center">
      <img class="w-[87px] h-[48px] relative" src="@/assets/images/error_icon.svg" />
      <h2 class="text-[28px] font-[700] leading-[34px] pt-[40px] pb-[8px]">원하시는 페이지를 찾을 수 없습니다.</h2>
      <p class="pb-[32px]">페이지가 존재하지 않거나 사용할 수 없는 페이지입니다.</p>
      <button class="bg-[#AB3B94] w-[148px] h-[46px] text-[white] rounded-[8px] font-[600] hover:bg-[#7a2a6a]"
        @click="replacePage">
        이전 페이지</button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'NotFound',

  data() {
    document.title = 'ONIONE | Not Found ERROR';
    return {};
  },
  methods: {
    replacePage(e) {
      e.preventDefault();
      this.$router.replace('/login');
    }
  },
  components: {},
};
</script>
