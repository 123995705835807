<template>
  <vue-final-modal
    v-if="checkupDetail"
    v-model="showCheckupDetailModal"
    name="showCheckupDetailModal"
    classes="modal-container"
    escToClose
    content-class="modal-content"
  >
    <div class="bg-[#ffffff] w-full">
      <div
        class="h-[56px] bg-[#373943] flex items-center px-[24px] sticky top-0 z-[20] justify-between"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">예약 정보</p>
        <button @click="hideDetailModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div
          class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
        >
          <div class="flex flex-col w-full space-y-[24px] text-[14px]">
            <div class="flex items-center">
              <p class="w-[100px] font-[600]">상태</p>
              <p>
                {{
                  checkupDetail?.status === 'REQUEST'
                    ? '예약 신청'
                    : checkupDetail?.status === 'COMPLETE'
                    ? '검진 완료'
                    : checkupDetail?.status === 'CANCEL'
                    ? '예약 취소'
                    : ''
                }}
              </p>

              <select
                v-if="checkupDetail?.status === 'CONFIRM'"
                v-model="selected.status"
                class="bg-white border border-[#DDDFE3] select-none w-full items-center px-[16px] py-[11.5px] rounded-[8px]"
              >
                <option
                  v-for="(item, index) in statusSelect"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">검진일</p>
              <p class="font-[400] text-onione-N700">
                {{ checkupDetail?.date }}
                <span v-if="checkupDetail?.date">
                  ({{
                    format(parseISO(checkupDetail?.date), 'eee', {
                      locale: ko,
                    })
                  }})
                </span>

                {{ checkupDetail?.startTime }} ~
                {{ checkupDetail?.endTime }}
              </p>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">검진 병원</p>
              <p class="text-onione-N700">
                {{ checkupDetail?.hospitalName ?? '' }}
              </p>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">검진 과목</p>
              <p class="text-onione-N700">
                {{ checkupDetail?.subject ?? '' }}
              </p>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">검진 인원</p>
              <p>{{ checkupDetail?.count ?? '' }}</p>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">신청 기관</p>
              <p>{{ checkupDetail?.institutionName ?? '' }}</p>
            </div>
            <div class="flex">
              <p class="w-[100px] font-[600] text-onione-N800">신청일</p>
              <p>{{ checkupDetail?.requestDate ?? '' }}</p>
            </div>
          </div>
        </div>
        <div class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px] justify-center">
            <button
              @click="onUpdateStatus(checkupDetail.id, selected.status)"
              class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import CheckupService from '@/services/CheckupService';
// import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import { useToast } from 'vue-toastification';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
export default {
  name: 'showCheckupModal',
  components: {},
  props: { checkupDetail: Object, getCheckupList: Function },

  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    selected: function () {
      return { status: this.checkupDetail.status };
    },
  },

  data: () => ({
    parseISO,
    format,
    ko,
    statusSelect: [
      { name: '예약 확정', value: 'CONFIRM' },
      { name: '예약 취소', value: 'CANCEL' },
    ],
    isLoading: false,
    page: 1,
    institutionPage: 1,
    totalPages: 1,
    currentElementCount: 1,
    showModal: false,
    modalType: '',

    showCheckupDetailModal: false,
    institutionPossibility: false,
    institutionValue: '',
    institutionId: '',

    showInstitutionModal: false,
    busPossibility: false,
    hospitalId: '',
    hospitalName: '',
    checkupDate: null,

    headerList: ['상태', '기관', '주소', '등록일', '연결병원', '선택'],

    searchValue: '',
    InstitutionList: {},
  }),

  methods: {
    onUpdateStatus(reservationId, status) {
      if (status === 'CANCEL' && this.checkupDetail.status === 'CONFIRM') {
        this.updateStatus(reservationId, status);
      } else {
        this.hideDetailModal();
      }
    },
    hideDetailModal() {
      this.$vfm.hide('showCheckupDetailModal');
    },

    async updateStatus(reservationId, status) {
      await CheckupService.updateStatus(reservationId, status)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            if (status === 'CONFIRM') {
              this.toast.success('예약이 확정되었습니다. ', {
                bodyClassName: ['custom-class-1', 'custom-class-2'],
              });
            } else if (status === 'CANCEL') {
              this.toast.success('예약이 취소되었습니다. ', {
                bodyClassName: ['custom-class-1', 'custom-class-2'],
              });
            } // else if

            this.$vfm.hide('showCheckupDetailModal');
            this.getCheckupList();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getReservationDeatilError', error))
        .finally(() => {});
    },
  },
};
</script>

<style></style>
