<template>
  <vue-final-modal
    @before-open="beforeOpenInstitutionDetailModal"
    @closed="closedInstitutionDetailModal"
    v-model="showInstitutionDetailModal"
    name="institutionDetailModal"
    classes="modal-container"
    content-class="modal-content"
    escToClose
  >
    <div class="h-full overflow-auto scrollbar-hide relative">
         <div
        class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
          <p class="font-[600] text-[#ffffff] text-[17px]">기관 상세 정보</p>
          <button @click="onCloseModal">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="w-[998px] bg-[#EEF0F4] p-[24px]">
          <div class="bg-[#ffffff] rounded-[12px] p-[32px] mb-[16px]">
            <div class="mb-[24px]">
              <button
                @click="
                  infoTab = '기관 정보';
                  getInstitutionInfo();
                "
                class="text-[15px] mr-[24px]"
                :class="[
                  infoTab === '기관 정보'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                기관 정보
              </button>
              <button
                @click="
                  infoTab = '계정 정보';
                  getAccountInfo();
                "
                class="text-[15px]"
                :class="[
                  infoTab === '계정 정보'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                계정 정보
              </button>
            </div>
            <table>
              <tbody v-if="infoTab === '기관 정보'">
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    기관 번호
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.id ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    기관명
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.name ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    상태
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{
                      institutionInfo.status === 'ACTIVE'
                        ? '정상'
                        : institutionInfo.status === 'DISABLED'
                        ? '종료'
                        : ''
                    }}
                  </td>
                </tr>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    담당병원
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.allocatedHospital ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    주소
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.streetAddress ?? '-' }}
                    <br />
                    {{ institutionInfo.detailAddress ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    연락처
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.phone ?? '-' }}
                  </td>
                </tr>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    이메일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.email ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    최근 검진일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.currentExaminationDate ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    검진 횟수
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.examinationCount ?? '-' }}
                  </td>
                </tr>
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    등록일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.serviceStartDate ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    운영 종료일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ institutionInfo.closeDate ?? '-' }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="infoTab === '계정 정보'">
                <tr class="h-[50px] border-y-[1px] border-y-[#EBECEF]">
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    아이디
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ accountInfo.memberAccountName ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    담당자 이메일
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ accountInfo.memberEmail ?? '-' }}
                  </td>
                  <td
                    class="w-[96px] bg-[#F6F8FA] text-center text-[13px] text-[#4E515F] font-[600]"
                  >
                    담당자 연락처
                  </td>
                  <td
                    class="w-[200px] pl-[12.5px] text-[13px] text-[#4E515F] font-[400]"
                  >
                    {{ accountInfo.memberPhone ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="w-full flex justify-end">
              <button
                class="text-[#9A9EAC] underline font-[400] mt-[15px] text-[13px]"
                @click="
                  infoTab === '기관 정보'
                    ? onShowInstitutionUpdateModal()
                    : onShowInstitutionAccountUpdateModal()
                "
              >
                정보 수정
              </button>
            </div>
          </div>
          <div class="bg-[#ffffff] rounded-[12px] h-[400px] p-[32px]">
            <div class="mb-[24px]">
              <button
                @click="
                  historyTab = '검진 내역';
                  page = 1;
                  getHistoryExamination();
                "
                class="text-[15px] mr-[24px]"
                :class="[
                  historyTab === '검진 내역'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                검진 내역
              </button>
              <button
                @click="
                  historyTab = '병원 변경 내역';
                  page = 1;
                  getHistoryHospital();
                "
                class="text-[15px]"
                :class="[
                  historyTab === '병원 변경 내역'
                    ? 'font-[700] text-onione-N800 border-onione-N800 border-b-[2px] leading-[24px]'
                    : 'font-[500] text-onione-N400',
                ]"
              >
                병원 변경 내역
              </button>
            </div>
            <div
              :class="[
                historyList?.pages?.length === 0
                  ? 'w-full bg-white h-[200px]'
                  : 'bg-white max-h-[280px] overflow-y-scroll scrollbar-hide  border-onione-N100 border-[1px] rounded-[12px]',
              ]"
              ref="HistoryDiv"
            >
              <LoadingSpinner v-if="isLoading"></LoadingSpinner>
              <table
                v-else
                class="bg-[#fff] rounded-[12px] w-full overflow-hidden"
              >
                <thead class="bg-onione-N50">
                  <tr>
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-onione-N100 border-b-[1px] text-[15px] leading-[18px]"
                      v-for="(column, index) in historyTab === '검진 내역'
                        ? historyHeader
                        : changeHeader"
                      :key="index"
                    >
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ column }}</p>
                        <div
                          class="absolute right-[0px] top-[calc(50%-8px)] h-[16px] bg-onione-N200"
                          :class="[
                            index ===
                            (historyTab === '검진 내역'
                              ? historyHeader.length - 1
                              : changeHeader.length - 1)
                              ? 'w-[0px]'
                              : 'w-[1px]',
                          ]"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody
                  v-if="
                    historyTab === '검진 내역' &&
                    historyList?.pages?.length !== 0
                  "
                >
                  <tr
                    v-for="(item, index) in historyList.pages"
                    :key="index"
                    class="border-onione-N100 border-b-[1px] last:border-none"
                  >
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.no ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.vehicleNumber ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.allocatedHospital ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.subject ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.patientCount ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.date }}
                        {{ item.startTime }} ~ {{ item.endTime }}
                      </p>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    historyTab === '병원 변경 내역' &&
                    changeList?.pages?.length !== 0
                  "
                >
                  <tr
                    v-for="(item, index) in changeList.pages"
                    :key="index"
                    class="border-onione-N100 border-b-[1px] last:border-none"
                  >
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.no ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.allocatedHospital ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.vehicleNumber ?? '-' }}
                      </p>
                    </td>

                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.modifiedDate ?? '-' }}
                      </p>
                    </td>
                    <td>
                      <p
                        class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center py-[20px]"
                      >
                        {{ item.modifiedId ?? '-' }}
                      </p>
                    </td>
                  </tr>
                </tbody>
                <div
                  v-if="
                    historyTab === '검진 내역' &&
                    historyList?.pages?.length === 0
                  "
                  class="w-full h-full flex items-center justify-center select-none mx-auto"
                >
                  <div
                    class="absolute left-[40%] bottom-[5%] flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>조회되는 검색 내역이 없습니다.</p>
                  </div>
                </div>

                <div
                  v-if="
                    historyTab === '병원 변경 내역' &&
                    changeList?.pages?.length === 0
                  "
                  class="w-full h-full flex items-center justify-center select-none mx-auto"
                >
                  <div
                    class="absolute left-[40%] bottom-[5%] flex flex-col items-center space-y-[24px]"
                  >
                    <img
                      class="w-[48px] h-[48px] relative"
                      src="@/assets/images/common_notice.svg"
                    />
                    <p>조회되는 검색 내역이 없습니다.</p>
                  </div>
                </div>
              </table>
            </div>
            <div
              class="w-full pt-[24px] pb-[40px] flex justify-center"
              v-if="
                historyTab === '검진 내역' && historyList?.pages?.length !== 0
              "
            >
              <pagination
                v-model="page"
                :records="
                  historyList.totalElementCount
                    ? Number(historyList?.totalElementCount)
                    : 1
                "
                :per-page="15"
                @paginate="paginate"
              />
            </div>

            <div
              class="w-full pt-[24px] pb-[40px] flex justify-center"
              v-if="
                historyTab === '병원 변경 내역' &&
                changeList?.pages?.length !== 0
              "
            >
              <pagination
                v-model="page"
                :records="
                  changeList?.totalElementCount
                    ? Number(changeList?.totalElementCount)
                    : 1
                "
                :per-page="15"
                @paginate="paginate"
              />
            </div>
          </div>
        </div>
      </div>
  </vue-final-modal>

  <InstitutionUpdateModal :getInstitutionInfo="getInstitutionInfo" />
  <InstitutionAccountUpdateModal :getAccountInfo="getAccountInfo" />
</template>

<script>
import Pagination from 'v-pagination-3';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import InstitutionService from '@/services/InstitutionService';
import InstitutionUpdateModal from '@/components/modal/InstitutionUpdateModal.vue';
import InstitutionAccountUpdateModal from '@/components/modal/InstitutionAccountUpdateModal.vue';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useToast } from 'vue-toastification';

export default {
  name: 'InstitutionDetailModal',
  components: {
    Pagination,
    LoadingSpinner,
    InstitutionUpdateModal,
    InstitutionAccountUpdateModal,
  },

  props: {
    getInstitutionList: Function,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    format,
    ko,
    parseISO,
    institutionId: null,
    isLoading: false,
    showInstitutionDetailModal: false,
    showInstitutionUpdateModal: false,
    institutionInfo: {},
    accountInfo: {},
    historyHeader: [
      'No.',
      '버스',
      '담당병원',
      '검진 과목',
      '검진 인원',
      '검진일',
    ],
    historyList: {},
    changeHeader: ['No.', '병원', '버스', '변경일', '변경 ID'],
    changeList: {},
    page: 1,
    infoTab: '기관 정보',
    historyTab: '검진 내역',
  }),

  methods: {
    onCloseModal() {
      this.showInstitutionDetailModal = false;
    },

    beforeOpenInstitutionDetailModal() {
      this.institutionId = this?.$vfm?.get(
        'institutionDetailModal'
      )?.[0]?.params?._value?.institutionId;

      this.getInstitutionInfo();
      this.getHistoryExamination();
    },

    closedInstitutionDetailModal() {
      this.page = 1;
      this.infoTab = '기관 정보';
      this.historyTab = '검진 내역';
      this.accountInfo = {};
      this.institutionInfo = {};
      this.changeList = {};
      this.getInstitutionList();
    },

    paginate() {
      if (this.historyTab === '검진 내역') this.getHistoryExamination();
      if (this.historyTab === '병원 변경 내역') this.getHistoryHospital();
    },

    onShowInstitutionUpdateModal() {
      this.$vfm.show('institutionUpdateModal', {
        institutionInfo: this.institutionInfo,
      });
    },

    onShowInstitutionAccountUpdateModal() {
      this.$vfm.show('institutionAccountUpdateModal', {
        accountInfo: this.accountInfo,
      });
    },

    async getInstitutionInfo() {
      this.isLoading = true;
      await InstitutionService.getInstitutionInfo(this.institutionId) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.institutionInfo = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getAccountInfo() {
      this.isLoading = true;
      await InstitutionService.getAccountInfo(this.institutionId) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.accountInfo = result.data.data;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getAccountInfoError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getHistoryExamination() {
      this.isLoading = true;
      await InstitutionService.getHistoryExamination(
        this.institutionId,
        this.page
      )
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.historyList = result.data.data;
            this.$refs.HistoryDiv.scrollTop = 0;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) =>
          console.error('getInstitutionExaminationError', error)
        )
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getHistoryHospital() {
      this.isLoading = true;

      await InstitutionService.getHistoryHospital(this.institutionId, this.page)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.changeList = result.data.data;
            this.$refs.HistoryDiv.scrollTop = 0;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) =>
          console.error('getInstitutionHospitalLinkError', error)
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
