<template>
  <vue-final-modal
    name="hospitalScheduleModal"
    v-model="showHospitalScheduleModal"
    classes="modal-container"
    content-class="modal-content"
    @before-open="beforeOpenHospitalScheduleModal"
    @closed="closedHospitalScheduleModal"
    escToClose
  >
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
      <div
        class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">
          {{ this?.schedule?.title }}
        </p>
        <button @click="onCloseModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px] w-[500px]">
        <div
          class="bg-[#ffffff] rounded-[12px] py-[40px] px-[33px] flex flex-col gap-[20px]"
        >
          <div class="flex gap-[24px] items-center">
            <p class="font-[600] text-[14px]">검진일자</p>
            <input
              v-if="this?.schedule?.dates"
              instanceof
              Date
              class="text-onione-N500 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] w-[311px]"
              type="text"
              disabled
              :value="
                format(this?.schedule?.dates, 'yyyy. MM. dd (eee)', {
                  locale: ko,
                })
              "
            />
          </div>

          <div class="flex gap-[24px] items-center">
            <p class="font-[600] text-[14px]">검진시간</p>
            <div class="flex flex-col gap-[10px]">
              <div class="flex items-center gap-[16px]">
                <label for="start-time" class="text-[14px] text-onione-N600"
                  >시작</label
                >
                <div class="flex gap-[10px]">
                  <select
                    :disabled="
                      this.schedule.customData.availableReservation === false
                        ? true
                        : false
                    "
                    v-model="this.schedule.customData.startTime"
                    name="startTime"
                    class="w-[270px]"
                    :class="[
                      this.schedule.customData.availableReservation === false
                        ? 'w-[166px] block text-Tiy-900 dark:text-gray-300 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] bg-[#FAFAFA]'
                        : 'w-[166px] block text-Tiy-900 dark:text-black-300 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] ',
                    ]"
                  >
                    <option
                      v-for="time in [
                        '09:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                      ]"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="flex items-center gap-[16px]">
                <label for="end-time" class="text-[14px] text-onione-N600"
                  >종료</label
                >
                <div class="flex gap-[10px]">
                  <select
                    :disabled="
                      this.schedule.customData.availableReservation === false
                        ? true
                        : false
                    "
                    v-model="this.schedule.customData.endTime"
                    name="endTime"
                    class="w-[270px]"
                    :class="[
                      this.schedule.customData.availableReservation === false
                        ? 'w-[166px] block text-Tiy-900 dark:text-gray-300 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] bg-[#FAFAFA]'
                        : 'w-[166px] block text-Tiy-900 dark:text-black-300 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] ',
                    ]"
                  >
                    <option
                      v-for="time in [
                        '09:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                      ]"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-[24px] items-center">
            <label class="font-[600] text-[14px]">검진과목</label>
            <CustomInput
              class="text-onione-N900 text-[14px] rounded-[8px] border-[1px] py-[11.5px] px-[16px] w-[311px]"
              type="text"
              :disabled="
                this.schedule.customData.availableReservation === false
                  ? true
                  : false
              "
              v-model="this.schedule.customData.subject"
            />
          </div>
          <div
            class="flex gap-[20px] items-center"
            v-if="this.schedule.kind === 'update'"
          >
            <label class="font-[600] text-[14px] min-w-[52px]">예약 정보</label>
            <p
              v-if="this.schedule.customData.availableReservation === true"
              class="text-[14px] text-onione-N500"
            >
              해당 날짜에 등록된 예약이 없습니다.
            </p>
            <div
              class="text-[14px] text-onione-N600"
              v-if="this.schedule.customData.availableReservation === false"
            >
              <div
                v-for="(reservation, index) in this.schedule.customData
                  .reservations"
                :key="index"
                class="flex flex-col items-start justify-start overflow-y-scroll max-h-[30px]  w-[311px] gap-[8px] "
              >
                <div class="flex gap-[8px]">
                  <span
                    class="text-[#ffffff] text-[12px] rounded-[4px] min-w-[54px] h-[22px] flex justify-center items-center cursor-default"
                    :class="[
                      reservation.status === 'CONFIRM'
                        ? 'bg-onione-B400'
                        : reservation.status === 'COMPLETE'
                        ? 'bg-onione-G400'
                        : reservation.status === 'REQUEST'
                        ? 'bg-onione-N300'
                        : 'bg-onione-N300',
                    ]"
                  >
                    {{
                      reservation.status === 'REQUEST'
                        ? '예약신청'
                        : reservation.status === 'CONFIRM'
                        ? '예약확정'
                        : reservation.status === 'COMPLETE'
                        ? '검진완료'
                        : reservation.status === 'CANCEL'
                        ? '예약취소'
                        : ''
                    }}
                  </span>

                <p>  {{ reservation.institutionName }}
                  ({{ reservation.patientCount }}명)</p>
                </div>
                
              </div>
              <p
                class="text-[14px] text-onione-R400"
                v-if="this.schedule.customData.availableReservation === false"
              >
                {{ schedule.reservations }}
              </p>
            </div>
          </div>
        </div>
        <!-- 등록 -->
        <div
          class="w-full flex mt-[24px] gap-[10px]"
          v-if="this.schedule.kind === 'create'"
        >
          <button
            class="w-full py-[14px] bg-onione-N0 rounded-[8px] text-onione-N600 border-[1px] border-onione-N200"
            @click="onCloseModal"
          >
            취소
          </button>
          <button
            v-if="
              this.schedule.customData.startTime !== '' &&
              this.schedule.customData.endTime !== '' &&
              this.schedule.customData.subject !== ''
            "
            class="w-full py-[14px] bg-onione-P400 rounded-[8px] text-onione-N0 font-[600]"
            @click="onScheduleCreate"
          >
            등록
          </button>
          <button
            v-else
            class="w-full py-[14px] bg-onione-P50 rounded-[8px] text-onione-N0 font-[600]"
          >
            등록
          </button>
        </div>
        <!-- 수정 - 예약수정가능 -->
        <div
          class="w-full flex mt-[24px] gap-[10px]"
          v-if="
            this.schedule.kind === 'update' &&
            this.schedule.customData.availableReservation === true
          "
        >
          <button
            class="w-full py-[14px] bg-onione-N0 rounded-[8px] text-onione-N600 border-[1px] border-onione-N200"
            @click="onCloseModal"
          >
            취소
          </button>
          <button
            class="w-full py-[14px] bg-onione-N400 rounded-[8px] text-onione-N0 font-[600]"
            @click="onScheduleDelete"
          >
            삭제
          </button>
          <button
            class="w-full py-[14px] bg-onione-P400 rounded-[8px] text-onione-N0 font-[600]"
            @click="onScheduleUpdate"
          >
            수정
          </button>
        </div>

        <div
          class="w-full justify-center flex mt-[24px] gap-[10px]"
          v-if="
            this.schedule.kind === 'update' &&
            this.schedule.customData.availableReservation === false
          "
        >
          <button
            class="w-[220px] py-[14px] bg-onione-P400 rounded-[8px] text-onione-N0 border-[1px] border-onione-N200"
            @click="onCloseModal"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { useToast } from 'vue-toastification';
import HospitalService from '@/services/HospitalService';
import { ko } from 'date-fns/locale';
import { format, parseISO, set } from 'date-fns';
import DateUtil from '../../utils/DateUtil';
import CustomInput from '@/components/common/CustomInput.vue';


export default {
  name: 'HospitalUpdateModal',
  components: {CustomInput},
  setup() {
    const toast = useToast();
    return { toast };
  },

  props: { getHospitalSchedule: Function, scheduleInfo: Object },

  data: () => ({
    format,
    parseISO,
    set,
    ko,

    hospitalId: '',

    schedule: {
      kind: '',
      title: '',
      customData: {
        availableReservation: '',
        startTime: '',
        endTime: '',
      },
      dates: null,
    },

    statusSelect: [
      { name: '정상', value: 'ACTIVE' },
      { name: '종료', value: 'DISABLED' },
    ],

    isLoading: false,
    showHospitalScheduleModal: false,
  }),

  methods: {
    onCloseModal() {
      this.showHospitalScheduleModal = false;
    },

    beforeOpenHospitalScheduleModal() {
      const newObj = JSON.parse(JSON.stringify(this.scheduleInfo));
      this.schedule = { ...newObj, dates: new Date(newObj.dates) };
      this.hospitalId = this?.$vfm?.get(
        'hospitalScheduleModal'
      )?.[0]?.params?._value.hospitalId;
    },

    closedHospitalScheduleModal() {
      this.schedule = {
        kind: '',
        title: '',
        customData: {
          availableReservation: '',
          startTime: '',
          endTime: '',
        },
        dates: null,
      };
    },

    onScheduleCreate() {
      if (
        !this.schedule.customData.startTime ||
        !this.schedule.customData.endTime
      )
        return alert('검진시간을 선택하세요');

      if (
        !DateUtil.validateStartTimeAndEndTime(
          this.schedule.customData.startTime,
          this.schedule.customData.endTime
        )
      )
        return alert('검진시간이 잘못되었습니다.');

      this.createSchedule({
        hospitalId: this.hospitalId,
        date: format(this.schedule?.dates, 'yyyy-MM-dd', { locale: ko }),
        startTime: this.schedule.customData.startTime,
        endTime: this.schedule.customData.endTime,
        subject: this.schedule.customData.subject,
      });
    },

    async createSchedule(schedule) {
      if (this.isLoading) {
        alert('처리중입니다. 잠시만 기다려주세요.');
        return;
      }

      this.isLoading = true;
      await HospitalService.createSchedule(schedule) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('검진 스케쥴이 등록되었습니다.');
            this.getHospitalSchedule();
            this.onCloseModal();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('createScheduleError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateSchedule(schedule) {
      if (this.isLoading) {
        alert('처리중입니다. 잠시만 기다려주세요.');
        return;
      }

      this.isLoading = true;
      await HospitalService.updateSchedule(schedule) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('검진 스케쥴이 수정되었습니다.');
            this.getHospitalSchedule();
            this.onCloseModal();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('createScheduleError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    onScheduleUpdate() {
      if (
        !this.schedule.customData.startTime ||
        !this.schedule.customData.endTime
      )
        return alert('검진시간을 선택하세요');

      this.updateSchedule({
        scheduleId: this.schedule.customData.scheduleId,
        startTime: this.schedule.customData.startTime,
        endTime: this.schedule.customData.endTime,
        subject: this.schedule.customData.subject,
      });
    },

    onScheduleDelete() {
      let change = confirm('스케쥴을 삭제하시겠습니까?');
      if (change === true) {
        this.deleteSchedule({
          scheduleId: this.schedule.customData.scheduleId,
        });
      } else {
        return;
      }
    },

    async deleteSchedule(scheduleId) {
      if (this.isLoading) {
        alert('처리중입니다. 잠시만 기다려주세요.');
        return;
      }

      this.isLoading = true;

      await HospitalService.deleteSchedule(scheduleId) //
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('검진 스케쥴이 삭제되었습니다.');
            this.getHospitalSchedule();
            this.onCloseModal();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('deleteScheduleError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
