<template>
  <vue-final-modal
    v-model="showModal"
    name="BusCreateModal"
    classes="modal-container"
    content-class="modal-content"
    escToClose
  >
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide relative">
      <div
        class="h-[56px] bg-[#373943] flex items-center px-[24px] sticky top-0 z-[20] justify-between"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">버스 등록</p>
        <button @click="showModal = false">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div
          class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]"
        >
          <div class="flex flex-col w-full">
            <div class="flex space-x-[24px] items-center">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[60px] whitespace-nowrap"
              >
                차량 번호
              </p>

              <input
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]"
                id="vehicleNumber"
                type="text"
                required
                v-model="vehicleNumber"
                autocomplete="off"
              />
            </div>
            <p
              v-show="vaildVehicleNumber"
              class="text-[13px] text-[#ff4869] pt-[20px] ml-[80px]"
            >
              올바른 차량번호를 입력해주세요. <br />
              구번호 : 부산11가1111 / 신번호 : 123가4567
            </p>
          </div>
        </div>
        <div class="modal__action">
          <div class="flex w-full mt-[24px] space-x-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="showModal = false"
            >
              취소
            </button>

            <button
              v-if="
                this.vehicleNumber?.length >= 7 &&
                vaildVehicleNumber === false &&
                !isLoading
              "
              @click="createBus"
              class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
            >
              등록
            </button>

            <div
              v-else
              class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BusService from '@/services/BusService';
import { useToast } from 'vue-toastification';

export default {
  name: 'BusCreateModal',
  props: {
    update: Function,
  },

  data: () => ({
    isLoading: false,
    vehicleNumber: '',
    vaildVehicleNumber: false,
    showModal: false,
  }),

  updated() {
    if (this.showModal === false) {
      this.vehicleNumber = '';
      this.vaildVehicleNumber = false;
    }
  },

  watch: {
    vehicleNumber: function () {
      this.checkBus();
    },
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    checkBus() {
      const VaildVehicleNumber =
        /^(([가-힣]{2}|[가-힣]{4})?\S?(\d{2}|\d{3})\S?[가-힣]\S?\d{4}$|임\S?[가-힣]{4,6}\S?(\d{6}|\d{4}))$/;

      if (this.vehicleNumber.length > 0) {
        if (
          !VaildVehicleNumber.test(this.vehicleNumber) ||
          !this.vehicleNumber
        ) {
          this.vaildVehicleNumber = true;

          return;
        }
        this.vaildVehicleNumber = false;
      } else {
        this.vaildVehicleNumber = false;
      }
    },

    confirm() {
      this.showModal = false;
      this.vehicleNumber = '';
    },
    async createBus() {
      this.isLoading = true;
      await BusService.createBus(this.vehicleNumber)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('버스 정보가 등록되었습니다.');
            this.showModal = false;
            this.$emit('update', 1, 'n99', '');
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('createBusError', error))
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },
  },
};
</script>

<style></style>
