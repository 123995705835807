<template>
    <vue-final-modal v-model="showModal" name="BusLinkDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
        <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
          <p class="font-[600] text-[#ffffff] text-[17px]">병원-버스 연결 삭제</p>
          <button @click="confirm">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div class="w-[30vw] bg-white py-[40px] rounded-[12px] items-center justify-center">
            <p class="text-[14px] text-[#373943] font-[700] w-full flex items-center justify-center">
              해당 병원-버스 연결을 삭제하시겠습니까?
            </p>
          </div>
          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="confirm">
                취소
              </button>
              <button v-if="!isLoading" @click="onDelete"
                class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer">
                삭제
              </button>

              <button v-else
                class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer'>
                삭제
              </button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import BusHospitalService from '@/services/BusHospitalService';
import { useToast } from 'vue-toastification';

export default {
  name: 'BusLinkDeleteModal',
  components: {},

  props: {
    delete: Function,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data: () => ({
    isLoading: false,
    busHospitalId: '',
    busId: '',
    showModal: false,
    modalType: '',
    hospitalValue: '',
    busValue: '',
  }),

  beforeUpdate() {
    if (this.busId === '' && this.busHospitalId === '') {
      this.busHospitalId =
        this?.$vfm?.get(
          'BusLinkDeleteModal'
        )?.[0]?.params?._value?.busHospitalId;
      this.busId =
        this?.$vfm?.get('BusLinkDeleteModal')?.[0]?.params?._value?.busId;
    }
  },

  updated() {
    if (this.showModal === false) {
      this.busHospitalId = '';
      this.busId = '';
      this.showModal = false;
    }
  },

  methods: {
    confirm() {
      this.busHospitalId = '';
      this.busId = '';
      this.showModal = false;
    },

    onDelete() {
      if (this.isLoading === true) return;
      this.deleteLink();
    },

    async deleteLink() {
      this.isLoading = true;
      await BusHospitalService.deleteLink(this.busId, this.busHospitalId)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('병원 - 버스 연결이 삭제되었습니다.');
            this.$emit('delete', 1, '', '');
            this.showModal = false;
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('deletelError', error))
        .finally(() => {
          setTimeout(() => { this.isLoading = false; }, 1000)
        });
    },
  },
};
</script>

<style></style>
