<template>
  <vue-final-modal v-model="showModal" name="BusLinkModifyModal" classes="modal-container" escToClose
    content-class="modal-content">
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
        <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
          <p class="font-[600] text-[#ffffff] text-[17px]">병원-버스 연결 수정</p>
          <button @click="confirm">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>
        <div class="bg-[#EEF0F4] p-[24px]">
          <div class="flex flxe-col w-[45.3vw] bg-white rounded-[12px] px-[32px] py-[53px]">

            <div class="flex flex-col w-full space-y-[46px]">
              <div class="flex items-center">
                <p class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[10%] whitespace-nowrap">
                  병원
                </p>
                <div class="w-[90%] h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center bg-[#FAFAFB]">
                  <p class="text-[14px] font-[400] text-[#7E8295]"> {{ hospitalValue }}</p>
                </div>
              </div>

              <div v-if="hospitalValue !== ''" class="flex items-center w-full">
                <p class="text-[#373943] font-[600] text-[14px] leading-[20px] w-[10%] whitespace-nowrap">
                  버스
                </p>

                <div class="w-[90%] flex">
                  <div @click="showBusLinkCreateModal = true"
                    class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer">
                    <p class="text-[14px] font-[400] text-[#B6B9C3]" v-if="busValue === ''"> 차량번호</p>
                    <p class="text-[14px] font-[400] text-[#202127]" v-else> {{ busValue }}</p>
                  </div>

                  <button @click="showBusLinkCreateModal = true"
                    class=" bg-[#65697B] h-[46px] ml-[10px] flex items-center justify-center space-x-[12px]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     cursor-pointer px-[31px] rounded-[8px]">
                    <p class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                      검색
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__action">
            <div class="flex w-full mt-[24px] space-x-[10px]">
              <button
                class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
                @click="confirm">취소</button>

              <button v-if="busValue !== '' && hospitalValue !== '' && !isLoading" @click="updateLink"
                class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer'>
                수정
              </button>

              <button v-else
                class='bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]'>
                수정
              </button>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- 기관선택 모달 -->
    <vue-final-modal v-model="showBusLinkCreateModal" classes="modal-container" content-class="modal-content" escToClose>
      <div class="bg-[#ffffff] w-[70vw] h-full overflow-auto scrollbar-hide">

        <div class="h-[56px] bg-[#373943] flex justify-between items-center px-[24px] sticky top-0">
          <p class="font-[600] text-[#ffffff] text-[17px]">버스 검색 </p>
          <button @click="onClose('showBusLinkCreateModal')">
            <img src="@/assets/images/modal_close_icon.svg" />
          </button>
        </div>

        <div class="bg-[#EEF0F4] p-[24px]">
          <div class="flex flex-col w-full bg-white rounded-[12px] px-[32px] py-[53px]">
            <div class="flex justify-between mb-[24px]">
              <div class="flex items-center justify-center">
                <input id='modify_bus' type='checkbox' v-model="busPossibility"
                  @change="getBusList(1, this.busPossibility, this.searchValue); this.page = 1">
                <label htmlFor='modify_bus'
                  class="flex items-center justify-center exst-[14px] ml-[10px] text-[#373943] font-[500] select-none">
                  연결 가능한 버스만 보기
                </label>
              </div>
              <div class="flex">
                <input className="h-[46px] w-[15.6vw] border-[1px] rounded-[8px] px-[20px]  placeholder:text-[14px]"
                  id="searchValue" type="text" v-model="searchValue" autocomplete="off" placeholder="차량번호"
                  @keyup.enter="getBusList(1, this.busPossibility, this.searchValue); this.page = 1" />

                <button @click="getBusList(1, this.busPossibility, this.searchValue); this.page = 1"
                  class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px]">
                  <p class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                    검색
                  </p>
                </button>

                <button @click="resetBusFilter"
                  class="bg-[#FFFFFF] h-[46px] flex items-center justify-center space-x-[12px] cursor-pointer px-[31px] rounded-[8px] border border-[#DDDFE3]">
                  <p class="text-[#65697B] text-[14px] font-[600] flex items-center justify-center whitespace-nowrap">
                    초기화
                  </p>
                </button>
              </div>
            </div>
            <LoadingSpinner v-if="isLoading"></LoadingSpinner>
            <div v-else class="min-h-[70vh]">
              <table class="bg-[#FAFAFB] rounded-[12px] w-full">
                <thead class="bg-[#EBECEF]">
                  <tr class="rounded-t-[12px]">
                    <th
                      class="relative py-[20px] px-[20px] items-center justify-center border-b-[#FAFAFB] border-r-[0px] border-l-[0px] border-t-[0px] border first:rounded-tl-[12px] last:rounded-tr-[12px] text-[15px] leading-[18px]"
                      v-for="(column, index) in busHeaderList" :key="index">
                      <div class="flex items-center justify-center">
                        <p class="font-[600]">{{ busHeaderList[index] }}</p>
                        <div class="w-[2px] bg-[#DDDFE3] h-[16px] absolute right-[0] items-center justify-center" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="busList?.pages?.length !== 0">
                  <tr v-for="(busItem, index) in busList.pages" :key="index">

                    <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                      <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                        {{
                          busItem.status === "ACTIVE"
                          ? "정상"
                          : busItem.status === "DISABLED"
                            ? "종료"
                            : ""
                        }}
                      </p>
                    </td>

                    <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                      <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                        {{ busItem.vehicleNumber ?? "-" }}
                      </p>
                    </td>
                    <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                      <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                        {{ busItem.createdDate }}
                      </p>
                    </td>

                    <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                      <p class="text-[#4E515F] text-[14px] font-[500] flex items-center justify-center">
                        {{ busItem.name ?? "-" }}
                      </p>
                    </td>

                    <td class="border border-b-[#EBECEF] border-r-[0px] border-l-[0px] py-[20px] px-[20px]">
                      <div class="w-full flex justify-center items-center">
                        <p @click="saveBusValue(busItem.vehicleNumber, busItem.busId)" v-if="busItem.possibility === true"
                          class="text-[#4E515F] text-[14px] font-[400] border-[1px] border-[#DDDFE3] rounded-[8px] py-[4px] flex justify-center items-center cursor-pointer w-full hover:bg-[#DDDFE3]  whitespace-nowrap">
                          선택
                        </p>
                        <p v-else>-</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div v-else class="w-full h-[50vh]  flex items-center justify-center select-none">
                  <div class="absolute left-1/2 transform -translate-x-1/2  flex flex-col items-center space-y-[24px]">
                    <img class="w-[48px] h-[48px] relative" src="@/assets/images/common_notice.svg" />
                    <p>일치하는 검색 결과가 없습니다.</p>
                  </div>
                </div>
              </table>
            </div>
          </div>
          <div class="w-full mt-[36px] flex justify-center items-center">
            <pagination v-model="buspage" :records="busList?.totalElementCount ? Number(busList?.totalElementCount) : 1
            " :per-page="15" @paginate="buspaginate" />
          </div>
        </div>
      </div>
    </vue-final-modal>
</template>

<script>
import BusHospitalService from "@/services/BusHospitalService";
import Pagination from "v-pagination-3";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import { useToast } from "vue-toastification";

export default {
  name: "BusLinkModifyModal",
  components: {
    Pagination,
    LoadingSpinner
  },

  props: {
    modify: Function
  },

  setup() {
    const toast = useToast();
    return { toast }
  },


  data: () => ({
    isLoading: false,
    buspage: 1,
    totalPages: 1,
    currentElementCount: 1,

    showModal: false,
    modalType: '',

    hospitalValue: '',
    busHospitalId: '',


    showBusLinkCreateModal: false,
    busPossibility: false,
    busId: "",
    busValue: "",


    headerList: [
      "상태",
      "병원",
      "주소",
      "등록일",
      "연결버스",
      "선택",
    ],

    busHeaderList: [
      "상태",
      "차량번호",
      "등록일",
      "연결병원",
      "선택",
    ],
    searchValue: "",
    hospitalList: {},
    busList: {},

  }),



  beforeUpdate() {
    if (this.hospitalValue === '' && this.busValue === '') {
      this.modalType =
        this?.$vfm?.get('BusLinkModifyModal')?.[0]?.params?._value?.modalType;
      this.busHospitalId =
        this?.$vfm?.get('BusLinkModifyModal')?.[0]?.params?._value?.busHospitalId;
      this.busId =
        this?.$vfm?.get('BusLinkModifyModal')?.[0]?.params?._value?.busId;
      this.hospitalValue =
        this?.$vfm?.get('BusLinkModifyModal')?.[0]?.params?._value?.name;
      this.busValue =
        this?.$vfm?.get('BusLinkModifyModal')?.[0]?.params?._value?.vehicleNumber;
    }
  },

  updated() {
    if (this.showModal === false) {
      this.modalType = ''
      this.busHospitalId = ''
      this.busId = ''
      this.hospitalValue = ''
      this.busValue = ''
    }

    if (this.showBusLinkCreateModal === true) {
      this.getBusList(this.page, this.busPossibility, this.searchValue);
    }

    if (this.showBusLinkCreateModal === false) {
      this.buspage = 1
      this.busPossibility = false
      this.searchValue = ''
    }
  },

  methods: {
    onClose(modal) {
      this[modal] = false;
    },
    confirm() {
      this.modalType = ''
      this.busHospitalId = ''
      this.busId = ''
      this.hospitalValue = ''
      this.busValue = ''
      this.showModal = false
    },

    buspaginate() {
      this.getBusList(this.buspage, this.busPossibility, this.searchValue);
    },

    saveBusValue(busValue, busId) {
      if (this.busValue !== busValue) {
        this.busValue = busValue;
        this.busId = busId;
        this.searchValue = ''
        this.showBusLinkCreateModal = false;
        this.buspage = 1
        this.busPossibility = false
        this.searchValue = ''
      }
    },

    resetBusFilter() {
      this.busPossibility = false;
      this.searchValue = ''
      this.page = 1;
      this.getBusList(this.page, this.busPossibility, this.searchValue);
    },


    async getBusList(page, status, hospitalValue) {
      this.isLoading = true;

      await BusHospitalService.getBusList(page, status, hospitalValue)
        .then((result) => {

          if (result?.data?.resultCode === 200) {
            this.busList = result.data.data;
          }

          else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message)
          }
        })
        .catch((error) => console.error("getBusListError", error))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateLink() {

      // if(this.isLoading){
      //   alert("처리중입니다. 잠시만 기다려주세요.");
      //   return;
      // }
      this.isLoading = true;

      await BusHospitalService.updateLink(this.busId, this.busHospitalId)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success("병원 - 버스 연결 정보가 수정되었습니다.");
            this.$emit('modify', 1, '', '')
            this.showModal = false;
          }

          else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message)

          }
        })
        .catch((error) => console.error("getHospitalError", error))
        .finally(() => {
          setTimeout(() => { this.isLoading = false; }, 1000)
        });
    },

  },

};
</script>

<style></style>
