<template>
  <vue-final-modal
    @closed="closedInstitutionCreateModal"
    v-model="showInstitutionCreateModal"
    name="institutionCreateModal"
    classes="modal-container"
    content-class="modal-content"
    escToClose
  >
    <div class="bg-[#ffffff] w-full overflow-auto scrollbar-hide">
      <div
        class="h-[56px] bg-[#373943] flex items-center justify-between px-[24px] sticky top-0"
      >
        <p class="font-[600] text-[#ffffff] text-[17px]">기관 등록</p>
        <button @click="onCloseModal">
          <img src="@/assets/images/modal_close_icon.svg" />
        </button>
      </div>
      <div class="bg-[#EEF0F4] p-[24px]">
        <div
          class="flex flex-col w-[45.3vw] bg-[#FFFFFF] rounded-[12px] px-[32px] py-[24px]"
        >
          <p
            class="border-b-[2px] font-[700] text-onione-N800 border-onione-N800 mb-[10px] w-[60px]"
          >
            기관 정보
          </p>
          <div class="flex flex-col w-full gap-[20px]">
            <div class="flex -[24px] items-center">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
              >
                기관명
              </p>

              <CustomInput
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                type="text"
                required
                v-model="institutionPayload.name"
                autocomplete="off"
                placeholder="기관명"
                maxlength="30"
              ></CustomInput>
            </div>

            <div class="flex -[24px]">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap relative top-[10%]"
              >
                주소
              </p>

              <div class="flex flex-col space-y-[10px] w-full">
                <div class="flex -[10px]">
                  <div
                    @click="execDaumPostcode()"
                    class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                  >
                    <p
                      class="text-[14px] font-[400] text-[#B6B9C3]"
                      v-if="institutionPayload.postNumber === ''"
                    >
                      우편번호
                    </p>
                    <p class="text-[14px] font-[400] text-[#202127]" v-else>
                      {{ institutionPayload.postNumber }}
                    </p>
                  </div>

                  <button
                    @click="execDaumPostcode()"
                    class="bg-[#65697B] h-[46px] mx-[10px] flex items-center justify-center -[12px] cursor-pointer px-[31px] rounded-[8px]"
                  >
                    <p
                      class="text-white text-[14px] font-[600] flex items-center justify-center whitespace-nowrap"
                    >
                      검색
                    </p>
                  </button>
                </div>
                <div
                  @click="execDaumPostcode()"
                  class="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] flex items-center cursor-pointer select-none hover:bg-[#F9F9FB]"
                >
                  <p
                    class="text-[14px] font-[400] text-[#B6B9C3]"
                    v-if="institutionPayload.streetAddress === ''"
                  >
                    주소
                  </p>
                  <p class="text-[14px] font-[400] text-[#202127]" v-else>
                    {{ institutionPayload.streetAddress }}
                  </p>
                </div>

                <CustomInput
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  type="text"
                  required
                  v-model="institutionPayload.detailAddress"
                  autocomplete="off"
                  placeholder="상세주소"
                  maxlength="50"
                />
              </div>
            </div>

            <div class="flex justify-between items-center gap-[20px]">
              <div class="flex items-center w-full">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
                >
                  연락처
                </p>
                <input
                  @input="checkPhoneNumber($event)"
                  maxlength="13"
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  name="phone"
                  type="text"
                  inputMode="decimal"
                  required
                  v-model="institutionPayload.phone"
                  autocomplete="off"
                  placeholder="연락처"
                />
              </div>

              <div class="flex items-center w-full">
                <p
                  class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
                >
                  이메일
                </p>
                <div class="w-full relative">
                  <input
                    className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                    type="text"
                    required
                    v-model="institutionPayload.email"
                    autocomplete="off"
                    placeholder="이메일"
                  />
                  <p
                    v-show="validEmail"
                    class="text-[13px] text-[#ff4869] mt-[10px] absolute top-[40px]"
                  >
                    이메일 주소를 정확히 입력해주세요.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-[45.3vw] bg-[#FFFFFF] rounded-[12px] px-[32px] py-[24px] mt-[10px]"
        >
          <p
            class="border-b-[2px] font-[700] text-onione-N800 border-onione-N800 mb-[10px] w-[60px]"
          >
            계정 정보
          </p>

          <div class="flex justify-between items-center gap-[20px] mb-[20px]">
            <div class="flex items-center w-full relative">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
              >
                아이디
              </p>
              <CustomInput
                @input="checkPhoneNumber($event)"
                maxlength="15"
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                type="text"
                required
                v-model="institutionPayload.account.memberAccountName"
                autocomplete="off"
                placeholder="아이디"
              />

              <p
                v-show="validId"
                class="text-[13px] text-[#ff4869] mt-[10px] absolute left-[80px] top-[40px]"
              >
                6~15자의 영문, 숫자만 사용 가능합니다.
              </p>
            </div>

            <div class="flex items-center w-full relative">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
              >
                비밀번호
              </p>
              <div class="w-full relative">
                <input
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  required
                  v-model="institutionPayload.account.memberPassword"
                  autocomplete="off"
                  placeholder="비밀번호"
                />
              </div>
              <p
                v-show="validPw"
                class="text-[13px] text-[#ff4869] mt-[10px] absolute left-[80px] top-[40px]"
              >
                8~15자의 영문,숫자,특수문자를 혼합하여 사용 가능합니다.
              </p>
            </div>
          </div>
          <div class="flex justify-between items-center gap-[20px]">
            <div class="flex items-center w-full">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
              >
                담당자 연락처
              </p>
              <input
                @input="checkPhoneNumber($event)"
                maxlength="13"
                className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px]  text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                name="memberPhone"
                type="text"
                inputMode="decimal"
                required
                v-model="institutionPayload.account.memberPhone"
                autocomplete="off"
                placeholder="담당자 연락처"
              />
            </div>

            <div class="flex items-center w-full">
              <p
                class="text-[#373943] font-[600] text-[14px] leading-[20px] min-w-[80px] whitespace-nowrap"
              >
                담당자 이메일
              </p>
              <div class="w-full relative">
                <input
                  className="w-full h-[50px] border-[1px] rounded-[12px] px-[20px] text-[14px] placeholder:text-[14px] placeholder:font-[400] placeholder:text-[#B6B9C3]"
                  name="memberEmail"
                  type="text"
                  required
                  v-model="institutionPayload.account.memberEmail"
                  autocomplete="off"
                  placeholder="담당자 이메일"
                />
                <p
                  v-show="validMemberEmail"
                  class="text-[13px] text-[#ff4869] mt-[10px] absolute top-[40px]"
                >
                  이메일 주소를 정확히 입력해주세요.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal__action">
          <div class="flex w-full mt-[24px] gap-[10px]">
            <button
              class="bg-[#FFFF] rounded-[8px] w-1/2 text-[#65697B] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
              @click="onCloseModal"
            >
              취소
            </button>

            <button
              @click="createInstitution"
              v-if="
                !isLoading &&
                institutionPayload.name !== '' &&
                institutionPayload.postNumber !== '' &&
                institutionPayload.streetAddress !== '' &&
                institutionPayload.email !== '' &&
                institutionPayload.phone !== '' &&
                institutionPayload.account.memberAccountName !== '' &&
                institutionPayload.account.memberPassword !== '' &&
                institutionPayload.account.memberEmail !== '' &&
                institutionPayload.account.memberPhone !== '' &&
                !validEmail &&
                !validPw &&
                !validId &&
                !validMemberEmail
              "
              class="bg-[#AB3B94] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px] cursor-pointer"
            >
              등록
            </button>

            <div
              v-else
              class="bg-[#F6E4F3] rounded-[8px] w-1/2 text-[#FFFFFF] font-[500] flex justify-center items-center py-[14px]"
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import InstitutionService from '@/services/InstitutionService';
import { useToast } from 'vue-toastification';
import CustomInput from '@/components/common/CustomInput.vue';
export default {
  name: 'InstitutionCreateModal',
  components: { CustomInput },

  props: {
    getInstitutionList: Function,
  },

  data: () => ({
    isLoading: false,
    showInstitutionCreateModal: false,
    validEmail: false,
    validId: false,
    validPw: false,
    validMemberEmail: false,
    institutionPayload: {
      name: '',
      postNumber: '',
      streetAddress: '',
      detailAddress: '',
      phone: '',
      email: '',
      account: {
        memberAccountName: '',
        memberPassword: '',
        memberEmail: '',
        memberPhone: '',
      },
    },
  }),

  watch: {
    'institutionPayload.email': function () {
      this.checkEmail('email');
    },
    'institutionPayload.account.memberEmail': function () {
      this.checkEmail('memberEmail');
    },
    'institutionPayload.account.memberAccountName': function () {
      this.checkId();
    },
    'institutionPayload.account.memberPassword': function () {
      this.checkPw();
    },
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    closedInstitutionCreateModal() {
      this.institutionPayload = {
        name: '',
        postNumber: '',
        streetAddress: '',
        detailAddress: '',
        phone: '',
        email: '',
        account: {
          memberAccountName: '',
          memberPassword: '',
          memberEmail: '',
          memberPhone: '',
        },
      };
    },

    onCloseModal() {
      this.showInstitutionCreateModal = false;
    },

    checkPhoneNumber(event) {
      switch (event.target.name) {
        case 'phone':
          this.institutionPayload.phone = event.target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
          break;
        case 'memberPhone':
          this.institutionPayload.account.memberPhone = event.target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      }
    },

    checkId() {
      const validateId = /^[A-Za-z]{1}[A-Za-z0-9]{5,14}$/;

      if (this.institutionPayload.account.memberAccountName !== '') {
        this.validId = !validateId.test(
          this.institutionPayload.account.memberAccountName
        );
      } else {
        this.validId = false;
      }
    },
    checkPw() {
      const pwPattern =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,15}$/;

      if (this.institutionPayload.account.memberPassword !== '') {
        this.validPw = !pwPattern.test(
          this.institutionPayload.account.memberPassword
        );
      } else {
        this.validPw = false;
      }
    },

    // 이메일 형식 검사

    checkEmail(type) {
      const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      switch (type) {
        case 'email':
          if (this.institutionPayload.email.length > 0) {
            if (
              !emailPattern.test(this.institutionPayload.email) ||
              !this.institutionPayload.email
            ) {
              this.validEmail = true;
              return;
            }
            this.validEmail = false;
          } else {
            this.validEmail = false;
          }
          break;
        case 'memberEmail':
          if (this.institutionPayload.account.memberEmail.length > 0) {
            if (
              !emailPattern.test(this.institutionPayload.account.memberEmail) ||
              !this.institutionPayload.account.memberEmail
            ) {
              this.validMemberEmail = true;
              return;
            }
            this.validMemberEmail = false;
          } else {
            this.validMemberEmail = false;
          }
          break;
      }
    },

    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if (this.extraAddress !== '') {
            this.extraAddress = '';
          }
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            this.institutionPayload.streetAddress = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            this.institutionPayload.streetAddress = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === 'R') {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              this.extraAddress += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== '' && data.apartment === 'Y') {
              this.extraAddress +=
                this.extraAddress !== ''
                  ? `, ${data.buildingName}`
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (this.extraAddress !== '') {
              this.extraAddress = `(${this.extraAddress})`;
            }
          } else {
            this.extraAddress = '';
          }
          // 우편번호를 입력한다.
          this.institutionPayload.postNumber = data.zonecode;
        },
      }).open({ popupKey: 'onione' }); // 생성시 키를 주면, 팝업이 중복으로 열리지 않습니다.
    },

    async createInstitution() {
      this.isLoading = true;
      await InstitutionService.createInstitution(this.institutionPayload)
        .then((result) => {
          if (result?.data?.resultCode === 200) {
            this.toast.success('기관 정보가 등록되었습니다.');
            this.showInstitutionCreateModal = false;
            this.getInstitutionList();
          } else if (result?.data?.resultCode !== 200) {
            this.toast.error(result?.data?.message);
          }
        })
        .catch((error) => console.error('getHospitalError', error))
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
