<template>
  <div
    class="w-full h-full flex justify-center text-[14px] mb-[100px] p-[20px]"
  >
    <div class="w-[500px] flex gap-[40px] flex-col">
      <div>
        <p class="">
          ㈜온택트헬스 (“회사”라 함)는 개인정보보호법 등 국내의 개인정보 보호
          법령을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자
          권익 보호에 최선을 다하고 있습니다.
        </p>
      </div>

      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보 수집 항목 및 이용 목적</h2>
        <p class="">
          “회사”는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
          개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
          변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할
          예정입니다.
        </p>
        <p class="">① “회사”가 처리하는 이용자의 개인정보는 다음과 같습니다.</p>
        <table class="text-[12px]">
          <thead>
            <tr>
              <th class="border-[1px] px-[10px]">구분</th>
              <th class="border-[1px] px-[10px]">처리항목</th>
              <th class="border-[1px] px-[10px]">처리목적</th>
              <th class="border-[1px] px-[10px]">보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border-[1px] px-[10px]">회원가입 (기관)</td>
              <td class="border-[1px] px-[10px]">
                기관명, 주소, 연락처, 이메일
              </td>
              <td class="border-[1px] px-[10px]">
                회원가입 확인, 회원 자격 유지 및 관리, 서비스 제공 및 상담
              </td>
              <td class="border-[1px] px-[10px]">탈퇴 시 까지</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">제휴병원 신청 (병원)</td>
              <td class="border-[1px] px-[10px]">
                병원명, 주소, 연락처, 담당자 연락처
              </td>
              <td class="border-[1px] px-[10px]">
                제휴 의사 확인, 회원 자격 유지 및 관리, 서비스 제공 및 상담
              </td>
              <td class="border-[1px] px-[10px]">제휴 서비스 종료 시 까지</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">
                버스 예약 접수 서비스 (기관)
              </td>
              <td class="border-[1px] px-[10px]">
                기관명, 주소, 연락처, 예약 정보
              </td>
              <td class="border-[1px] px-[10px]">
                서비스 이용 시 병원에 정보 제공 용도
              </td>
              <td class="border-[1px] px-[10px]">목적 달성 시</td>
            </tr>
          </tbody>
        </table>
        <p class="">
          ② “회사”는 개인정보 처리방침을 사이트 첫 화면과 고객센터의 ‘개인정보
          처리방침’ 항목에서 공개함으로써 회원들이 언제나 용이하게 보실 수
          있도록 조치하고 있습니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보의 제 3자 제공</h2>
        <p class="">
          “회사”는 회원의 개인정보를 동의없이 제 3자에게 제공하지 않습니다.
          다만, 법령의 규정에 의한 경우는 예외로 합니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보의 위탁처리</h2>
        <p class="">
          ① “회사”는 서비스 제공의 안정성과 최신 기술을 이용자에게 제공하기 위해
          국외에 개인정보를 위탁하고 있으며, 이용자로부터 취득 또는 생성한
          개인정보를 AWS ( Amazon Web Services Inc. ) 보유하고 있는
          데이터베이스에 저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하고,
          이용자의 개인정보에 접근할 수 없습니다.
        </p>
        <table class="text-[12px]">
          <thead>
            <tr>
              <th class="border-[1px] px-[10px]">수탁업체</th>
              <th class="border-[1px] px-[10px]">이전항목</th>
              <th class="border-[1px] px-[10px]">이전국가</th>
              <th class="border-[1px] px-[10px]">이전 일시 및 방법</th>
              <th class="border-[1px] px-[10px]">개인정보 보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border-[1px] px-[10px]">AWS</td>
              <td class="border-[1px] px-[10px]">
                서비스 이용 기록 또는 수집된 개인정보
              </td>
              <td class="border-[1px] px-[10px]">
                한국, 일본 ( AWS 도쿄리전 )
              </td>
              <td class="border-[1px] px-[10px]">
                서비스 이용시점에 네트워크를 통한 전송
              </td>
              <td class="border-[1px] px-[10px]">서비스 변경 시까지</td>
            </tr>
          </tbody>
        </table>
        <p class="">
          ② “회사”는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지,
          기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상
          등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
          안전하게 처리하는 지를 감독하고 있습니다.
        </p>
        <p class="">
          ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보
          처리방침을 통해 공개합니다.
        </p>
      </div>

      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보 파기절차 및 방법</h2>
        <p class="">
          “회사”는 원칙적으로 개인정보 처리목적이 달성된 경우 지체없이 해당
          개인정보를 파기하며, 파기절차 및 파기방법은 다음과 같습니다.
        </p>
        <p class="">파기 절차</p>
        <p class="">
          수집 목적이 달성된 후 별도의 DB에 옮겨져 ( 종이의 경우 별도의 서류 )
          내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시
          파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우
          외에는 다른 목적으로 이용되지 않습니다.
        </p>
        <p class="">② 파기 방법</p>
        <p class="">
          전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
          삭제하며, 종이에 출력된 정보는 분쇄기로 분쇄하거나 소각하여
          파기합니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">이용자 및 법정대리인의 권리</h2>
        <p class="">
          이용자 또는 법정대리인은 “회사”에 대해 언제든지 개인정보보호 관련
          권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 “회사”의 개인정보
          처리방침에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수
          있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수
          있습니다.
        </p>
        <p class="">
          ① 개인정보 조회, 수정을 위해서는 서비스 내 “정보 수정”을, 탈퇴를
          위해서는 “회사”의 전자우편 또는 전화를 통해 탈퇴 신청을 한 후 본인
          확인 절차를 거쳐서 정정 또는 탈퇴가 가능합니다.
        </p>
        <p class="">
          ② 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락한 경우에도
          지체 없이 조치합니다.
        </p>
        <p class="">
          ③ “회사”는 이용자 또는 법정대리인의 요청에 의해 해지 또는 삭제된
          개인정보를 개인정보의 보유기간에 명시된 바에 따라 처리하고 그 외의
          용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">쿠키 활용</h2>
        <p class="">
          ① 서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용기록, 기기정보가
          생성되어 수집될 수 있습니다. 서비스 이용과정에서 이용자에 관한 정보를
          “회사”가 자동화된 방법으로 생성하여 이를 저장할 수 있습니다.
        </p>
        <p class="">
          ② 쿠키는 웹 서비스 제공자의 서버가 이용자의 컴퓨터로 전송하는 소량의
          정보이며, 여기에는 방문한 웹사이트의 정보 등이 담겨 있습니다. 쿠키는
          이용자 컴퓨터의 웹 브라우저는 식별하지만 이용자를 개인적으로
          식별하지는 않으며, 이용자는 웹 브라우저의 옵션 메뉴를 통해 쿠키의 수용
          여부 등을 설정할 수 있습니다.
        </p>
        <p class="">
          ③ “회사”는 쿠키를 통해 별도로 개인정보를 수집하거나 활용하지 않습니다.
          방문자는 쿠키에 대해 선택권을 가지고 있습니다.
        </p>
        <p class="">
          ④ “회원”은 웹 브라우저 상단의 [ IE : 도구 - 인터넷 옵션 - 개인정보 -
          고급정보 / Chrome : 설정 – 고급 – 콘텐츠 설정 – 쿠키 ] 에서 모든
          쿠키의 허용, 동의를 통한 쿠키의 허용, 모든 쿠키의 차단을 스스로 결정할
          수 있습니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보보호 정보보호 담당부서</h2>
        <p class="">
          ① “회사”는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이
          개인정보 보호책임자 및 담당부서를 지정하고 있습니다.
        </p>
        <table class="text-[12px] w-full">
          <tbody>
            <tr>
              <td class="border-[1px] px-[10px]">개인정보 보호책임자</td>
              <td class="border-[1px] px-[10px]">최대훈</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">담당부서</td>
              <td class="border-[1px] px-[10px]">개발팀</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">전화번호</td>
              <td class="border-[1px] px-[10px]">02-362-9610</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">이메일 주소</td>
              <td class="border-[1px] px-[10px]">cdh82@ontacthealth.com</td>
            </tr>
          </tbody>
        </table>
        <p class="">
          ② 이용자는 “회사”의 서비스를 이용하면서 발생한 모든 개인정보보호 관련
          문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
          담당부서로 문의하실 수 있습니다. “회사”는 이용자의 문의에 대해 지체
          없이 답변 및 처리합니다.
        </p>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">권익침해 구제방법</h2>
        <p class="">
          “회원”은 개인정보침해로 인한 구제를 받기 위하여 개인정보침해신고센터,
          대검찰청 사이버수사과 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
        </p>
        <p>① 개인정보침해신고센터</p>
        <table class="text-[12px] w-full">
          <tbody>
            <tr>
              <td class="border-[1px] w-[30%] px-[10px]">홈페이지</td>
              <td class="border-[1px] px-[10px]">privacy.kisa.or.kr</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">전화</td>
              <td class="border-[1px] px-[10px]">(국번없이) 118</td>
            </tr>
          </tbody>
        </table>
        <p>② 대검찰청 사이버수사과</p>
        <table class="text-[12px] w-full">
          <tbody>
            <tr>
              <td class="border-[1px] w-[30%] px-[10px]">홈페이지</td>
              <td class="border-[1px] px-[10px]">spo.go.kr</td>
            </tr>
            <tr>
              <td class="border-[1px] px-[10px]">전화</td>
              <td class="border-[1px] px-[10px]">(국번없이) 1301</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col gap-[10px]">
        <h2 class="font-[600]">개인정보 처리방침의 변경</h2>
        <p class="">
          “회사”는 개인정보 처리방침을 변경하는 경우에 개인정보주체인 “회원”이
          그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지
          쉽게 알 수 있도록 지속적으로 서비스를 통하여 공개합니다.
        </p>
      </div>
      <div>
        <p class="">공지일자 : 2023년 3월 23일</p>
        <p class="">시행일자 : 2023년 3월 23일</p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
